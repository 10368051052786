.warning-text {
	margin: 0;
	padding: 0;
	width: 100%;
	margin-bottom: 30px;
}

/* .warning-text p {
      color: #6A6A6B;
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
  } */

.partnerrequest-button {
	max-width: 100%;
	margin-top: 0px;
	margin-left: 50px;
	max-width: 710px;
	width: 100%;
}

.partners-container {
	padding-top: 0 !important;

	@media (max-width: 1060px) {
		margin-top: -20px;
	}

	@media (max-width: 480px) {
		margin-top: 0;
	}
}

.terms-form .dropdown:last-of-type {
	margin-bottom: 0;
}

@media (max-width: 1060px) {
	.partners-container {
		padding-top: 80px;
	}

	.partnerrequest-button {
		margin-left: 0;
	}
}

@media (max-width: 480px) {
	.partners-container {
		padding-top: 90px;
	}

	.partnerrequest-button {
		margin-top: 35px;
	}
}
