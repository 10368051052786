.pass-container {
    max-width: 730px;
    width: 100%;
  }
  
  .passchange-header {
    color: #8A7DE7;
    font-size: 26px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 15px;
  }
  
  .passchange-text {
    color: #6A6A6B;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .passchange-form {
    max-width: 550px;
    width: 100%;
    margin-top: 40px;
  }
  
  .show-password {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 2;
    justify-content: center;
    align-items: center;
    display: flex;
  
    svg {
      width: 22px;
      height: 22px;
    }
  }
  
  .reset-password-modal {
    color: #8A7DE7;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    transition: all 0.3s;
    position: relative;
    display: block;
    text-decoration: none !important;
    background: none;
    border: none;
    cursor: pointer;
  
    &::before {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      border-radius: 1px;
      opacity: 0.5;
      background: #8A7DE7;
      display: block;
      height: 1px;
    }
  
    &:hover {
      color: #4E41AB;
    }
  }
  
  .passchange-form .block-save {
    margin-top: 0;
  }
  
  @media (max-width: 1060px) {
    .pass-container {
      max-width: 550px;
      width: 100%;
    }
  
    .passchange-form {
      margin-top: 50px;
      max-width: 550px;
    }
  }
  
  @media (max-width: 768px) {
    .passchange-header {
      font-size: 23px;
      line-height: 29px;
      margin-bottom: 10px;
    }
  }
  
  @media (max-width: 480px) {
    .pass-container {
      max-width: 100%;
      width: 100%;
      padding-top: 15px;
    }
  
    .passchange-form {
      margin-top: 30px;
      max-width: 100%;
    }
  
    .passchange-header {
      font-size: 17px;
      line-height: 23px;
    }
  
    .passchange-text {
      font-size: 11px;
      line-height: 17px;
    }
  
    .reset-password-modal {
      margin-top: 15px;
      font-size: 12px;
      line-height: 16px;
    }
  }
  