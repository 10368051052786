.help {
	max-width: 920px;
	width: 100%;
}

.help-header {
	margin-bottom: 50px;
}

.help-header h1 {
	color: #8a7de7;
	font-size: 26px;
	font-weight: 500;
	line-height: 32px;
	margin-bottom: 15px;
}

.help-header p {
	color: #6a6a6b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	a {
		color: #6a6a6b;
	}
}

.help-dropdown {
	overflow: hidden;
	border-radius: 10px;
	background: #fff;
	transition: max-height 0.5s ease-in-out;
	max-height: 60px;
	margin-bottom: 15px;
}

.help-dropdown-header {
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	transition: all 0.3s;
	box-sizing: border-box;
	padding: 17px;
	height: 60px;
}

.help-dropdown-header h3 {
	color: #333;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
	letter-spacing: 0.18px;
}

.help-dropdown-header:hover {
	color: #8a7de7;
}

.help-dropdown-header i {
	display: flex;
	justify-content: center;
	align-items: center;
}

.help-dropdown-header path {
	transition: all 0.3s;
}

.help-dropdown-header:hover path {
	stroke: #8a7de7;
}

.help-dropdown-header.active i {
	transform: rotate(180deg);
}

.help-dropdown-header.active path {
	stroke: #8a7de7;
}

.help-dropdown.active {
	max-height: 1000px;
	transition: max-height 0.5s ease-in-out;
}

.help-dropdown-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
	box-sizing: border-box;
	padding: 0 17px 17px 17px;
}

.help-dropdown-content.open {
	max-height: 100%;
	transition: max-height 0.5s ease-in-out;
}

.help-dropdown-content p {
	color: #6a6a6b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.16px;
}

.help-dropdown-content ol,
.help-dropdown-content ul {
	padding-left: 17px;
	box-sizing: border-box;
}

.help-dropdown-content li {
	color: #6a6a6b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0.16px;
	margin-left: 10px;
}

@media (max-width: 1060px) {
	.help-header {
		margin-bottom: 40px;
	}

	.help-header h1 {
		font-size: 24px;
		line-height: 30px;
	}

	.help-header p br {
		display: none;
	}

	.help-dropdown-header h3 {
		font-size: 15px;
		line-height: 22px;
		letter-spacing: 0.15px;
	}

	.help-dropdown-content p {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.14px;
	}

	.help-dropdown-content li {
		font-size: 14px;
		line-height: 22px;
		letter-spacing: 0.14px;
	}
}

@media (max-width: 768px) {
	.help-header {
		margin-bottom: 30px;
	}

	.help-header h1 {
		font-size: 23px;
		line-height: 29px;
		margin-bottom: 10px;
	}
}

@media (max-width: 480px) {
	.help-header h1 {
		font-size: 17px;
		line-height: 23px;
		margin-bottom: 10px;
	}

	.help-dropdown-header svg {
		width: 24px;
		height: 24px;
	}

	.help-header p {
		font-size: 11px;
		line-height: 17px;
	}

	.help-dropdown-header {
		padding: 10px;
	}

	.help-dropdown-header h3 {
		font-size: 12px;
		line-height: 17px;
		letter-spacing: 0.12px;
	}

	.help-dropdown-content p {
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 0.1px;
	}

	.help-dropdown-content li {
		font-size: 10px;
		line-height: 18px;
		letter-spacing: 0.1px;
	}

	.help-dropdown-content {
		padding: 0 10px 10px 10px;
	}
}
