.single-select__control {
	border-radius: 10px !important;
	background-color: #ebf1f8 !important;
	width: 100%;
	height: 58px;
	border: none !important;
	cursor: pointer !important;
}

.single-select__single-value {
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
}

.single-select__indicators {
	width: auto;
}

.single-select__indicator-separator {
	display: none !important;
}

.single-select__indicator {
	padding: 0 !important;
	margin-right: 15px;
	width: 20px;
	height: 20px;

	svg {
		fill: #333333 !important;
		stroke: #333333 !important;
	}
}

.single-select__input {
	opacity: 0 !important;
	outline: none !important;
}

.single-select__menu {
	z-index: 33 !important;
}

.single-select__option {
	cursor: pointer !important;
}

.single-select__option--is-selected {
	background: #8a7de7;
}

.single-select__value-container {
	padding: 0 !important;
	box-sizing: border-box;
	padding-left: 15px !important;
}

.single-select__menu {
	border-radius: 10px;
	background: #f9f9fb;
	box-sizing: border-box;
	z-index: 33;
}

.single-select__option {
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 400;
	line-height: 17px;
}

.single-select__option--is-selected,
.single-select__option--is-focused {
	background: #ebf1f8 !important;
}

.language-select__input-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 20px;
	opacity: 0;
	top: 0px;
	&.input-enter {
		top: 5px;
		opacity: 1;
	}
}

.language-select__control {
	border-radius: 10px !important;
	background-color: #ebf1f8 !important;
	width: 100%;
	height: 58px;
	border: none !important;
	cursor: pointer !important;

	@media (max-width: 480px) {
		height: 50px;
	}
}

.language-select__indicator-separator {
	display: none !important;
}

.language-select__indicators {
	width: auto;
}

.language-select__indicator svg {
	fill: #333333 !important;
	stroke: #333333 !important;
}

.language-select__value-container {
	width: 100%;
	height: 100%;
}

.language-select__menu {
	border-radius: 10px;
	background: #f9f9fb;
	box-sizing: border-box;
	padding: 15px;
	z-index: 33 !important;
}

.custom-option {
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	gap: 10px;
	cursor: pointer;
	transition: all 0.3s;
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;

	&:last-child {
		margin-bottom: 0;
	}
}

.language-select__value-container {
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	box-sizing: border-box;
	padding: 0 !important;
	padding-left: 20px !important;

	@media (max-width: 480px) {
		padding-left: 13px !important;
	}
}

.custom-option:hover {
	color: #4e41ab !important;
}

.language-select__menu-list img {
	width: 29px;
	height: 19px;
}

.language-select__indicator {
	padding: 0 !important;
	margin-right: 15px;
	width: 20px;
	height: 20px;
}

.language-select__menu-list {
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-track {
		background: #ebeaed;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: #f2ecff;
	}
}

.select-block {
	position: relative;
	margin-bottom: 20px;

	.input-placeholder {
		position: absolute;
		left: 20px;
		top: 8px;
		top: 22px;
		font-family: 'Montserrat';
		color: #777;
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		transition: all 0.3s;
		z-index: 4;
		font-size: 15px;

		@media (max-width: 480px) {
			left: 13px !important;
			font-size: 12px;
			top: 18px;
		}
	}

	&.active .input-placeholder {
		top: 10px;
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
		@media (max-width: 480px) {
			top: 8px;
			font-size: 10px;
		}
	}
}

.language-select__value-container--has-value {
	padding-top: 20px !important;
	z-index: 5;
}

.inputs-row .select-block {
	flex: 1;
}

.select-block {
	p {
		margin-bottom: 10px;
		color: #333;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		display: flex;
		align-items: center;

		span {
			color: #f00;
		}
	}

	.input-note {
		position: relative;
		top: 0;
		transform: translateY(0%);
		right: 0;
		margin-left: 7px;
	}

	&.error .language-select__control {
		border: 1px solid red !important;
	}

	.language-select__input-container {
		grid-area: auto !important;
	}

	.input-note {
		position: relative;
		top: 0;
		transform: translateY(-50%);
		margin-left: 7px;
		position: absolute;
		top: 50%;
		right: 50px;
		z-index: 5;
	}

	&#distributor-field {
		.input-note {
			right: -10px;
		}
	}
}

.select-placeholder {
	position: absolute;
	left: 20px;
	top: 20px;
	font-family: 'Montserrat';
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;
	transition: all 0.3s;

	span {
		color: #f00;
	}
}

.multi-select-stroke__control {
	border-radius: 10px !important;
	background-color: #ebf1f8 !important;
	width: 100%;
	min-height: 58px !important;
	border: none !important;
	cursor: pointer !important;
}

.multi-select-stroke__value-container {
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	box-sizing: border-box;
	padding: 0 !important;
	padding-left: 20px !important;
	width: 100%;
	height: 100%;
	min-height: 58px;
}

.multi-select-stroke__input-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	padding: 0 !important;
	margin: 0 !important;
}

.multi-select-stroke__indicators {
	width: auto;
}

.multi-select-stroke__indicator-separator {
	display: none !important;
}

.multi-select-stroke__indicator {
	svg {
		fill: #333333 !important;
		stroke: #333333 !important;
	}

	padding: 0 !important;
	margin-right: 15px;
	width: 20px;
	height: 20px;
}

.multi-select-stroke__menu {
	z-index: 33 !important;
}

.multi-select-stroke__menu-list {
	&::-webkit-scrollbar {
		width: 7px;
	}

	&::-webkit-scrollbar-track {
		background: #f2ecff;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: #8a7de7;
	}

	scrollbar-width: thin;
	scrollbar-color: #8a7de7 #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: #8a7de7;
}

.multi-select-stroke__option {
	padding: 10px 15px !important;
	cursor: pointer !important;
	transition: all 0.3s;
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 16px !important;
	background: #fff !important;
}

.multi-select-stroke__single-value {
	color: #333;
}

.multi-select-stroke__option--is-focused {
	background: #ebf1f8 !important;
}

/* .multi-select-stroke__option--is-selected {
	background: #ebf1f8 !important;
} */

.multi-select-stroke__multi-value {
	background: none !important;
}

.multi-select-stroke__multi-value__label {
	color: #333 !important;
	font-size: 15px !important;
	font-weight: 400 !important;
	line-height: 17px !important;
	padding: 0 !important;

	&::after {
		content: ',';
	}
}

.multi-select-stroke__multi-value:nth-last-child(-n + 2)
	.multi-select-stroke__multi-value__label::after {
	display: none;
}

.multi-select-stroke__multi-value__remove {
	display: none !important;
}

.multi-select-stroke__value-container--has-value {
	padding-top: 25px !important;
	padding-bottom: 10px;
	z-index: 5;
}

.select-gender .multi-select-stroke__value-container--has-value {
	padding-top: 0 !important;
}

.multi-select-search__control {
	border-radius: 10px !important;
	background: none !important;
	width: 100%;
	min-height: 58px !important;
	border: 1px solid #555 !important;
	cursor: pointer !important;
}

.multi-select-search__value-container {
	color: #333 !important;
	font-family: 'Montserrat';
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	box-sizing: border-box;
	padding: 0 !important;
	padding-left: 15px !important;
	width: 100%;
	height: 100%;
}

.multi-select-search__input-container {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	opacity: 0;
	padding: 0 !important;
	margin: 0 !important;
}

.multi-select-search__input {
	opacity: 0 !important;
}

.multi-select-search__indicator-separator {
	display: none !important;
}

.multi-select-search__menu {
	z-index: 33 !important;
	top: 100%;
	position: absolute;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #555;
	background: #f9f9fb;
	width: 300px;
	right: 0;
	box-sizing: border-box;
	padding: 15px;
	margin-top: 15px;
}

.multi-select-search__menu-list {
	overflow: hidden;
	overflow-y: auto;
	max-height: 166px;
}

.multi-select-search__add-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 38px;
	height: 38px;
	margin-right: 15px;
	border-radius: 5px;
	border: 1px solid #8a7de7;
	transition: all 0.3s;
	cursor: pointer;

	path {
		transition: all 0.3s;
	}

	&:hover {
		background: #4e41ab;

		path {
			stroke: #fff;
		}
	}
}

.multi-select-search__search {
	margin-bottom: 10px;

	input {
		background: none !important;
		border: none !important;
		outline: none !important;
		margin: 0 !important;
		width: 100% !important;
		height: 100% !important;
		padding: 0 !important;
	}

	label {
		cursor: pointer;
		position: relative;
		width: 100%;
		height: 27px;
		display: block;

		i {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.multi-select-search__multi-value {
	z-index: 4;
}

.custom-option {
	position: relative;
	z-index: 2;
}

.multi-select-search__multi-value {
	border-radius: 5px !important;
	background: #1e2321 !important;
	box-sizing: border-box;
	padding: 8px 10px !important;
	color: #fff;
	transition: all 0.3s;

	&:hover {
		background: #323735 !important;
	}
}

.multi-select-search__multi-value__label {
	color: #fff !important;
	font-size: 15px !important;
	font-weight: 400 !important;
	line-height: 18px !important;
	padding: 0 !important;
}

.css-12a83d4-MultiValueRemove:hover,
.multi-select-search__multi-value__remove:hover {
	background: none !important;
	color: #fff !important;
}

.setting-select {
	position: relative;
	margin-bottom: 15px;
	background: #ebf1f8;
	border-radius: 10px;

	&:last-child {
		margin-bottom: 0;
	}

	.setting-select__placeholder {
		color: #777;
		font-size: 15px;
		font-weight: 400;
		line-height: 17px;
		position: absolute;
		top: 20px;
		left: 15px;
	}

	&.active .setting-select__placeholder {
		top: 10px;
		color: #777;
		font-size: 12px;
		line-height: 15px;
	}
}

.settings-select__control {
	max-width: 350px !important;
	width: 100% !important;
	height: 58px !important;
	border-radius: 10px !important;
	background: none !important;
	border: none !important;
	cursor: pointer !important;
	outline: none !important;
	box-shadow: none !important;

	&.settings-select__control--menu-is-open {
		border-radius: 10px 10px 0 0 !important;
	}

	&.settings-select__control--is--focused {
		outline: none !important;
		border: none !important;
	}
}

.settings-select__value-container {
	padding: 0 !important;
	margin: 0 !important;
	box-sizing: border-box;
	padding-left: 16px !important;
	padding-top: 0 !important;
	height: 100%;
}

.settings-select__single-value {
	padding: 0 !important;
	margin: 0 !important;
	color: #333 !important;
	font-size: 15px !important;
	line-height: 17px !important;
	letter-spacing: 0.3px !important;
}

.settings-select__custom-value {
	img {
		width: 30px;
		height: 20px;
	}

	display: flex;
	height: 100%;
	gap: 10px;
	padding-top: 5px;
}

.settings-select__menu-list::-webkit-scrollbar {
	width: 3px;
}

.settings-select__menu {
	padding: 0 !important;
	margin: 0 !important;
	border: none !important;
	background: #ebf1f8 !important;
	box-shadow: none !important;
	border-radius: 0 0 10px 10px !important;
	overflow: hidden;
	position: absolute !important;
	transform: translateY(-5px);
	z-index: 10 !important;
}

.settings-select__menu-list {
	padding: 0 !important;
	margin: 0 !important;
	max-height: 150px !important;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #ebeaed;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: #8a7de7;
	}

	scrollbar-width: thin;
	scrollbar-color: #8a7de7 #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: #8a7de7;
}

.settings-select__option {
	box-sizing: border-box;
	padding: 10px 15px;
	border: 3px solid #ebf1f8;
	background: #ebf1f8;
	color: #333;
	font-size: 15px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0.3px;
	cursor: pointer;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	gap: 10px;

	img {
		width: 33px;
		height: 23px;
	}

	&:hover {
		background: #fff;
	}
}

.settings-select__indicators {
	padding: 0;
	margin: 0;
	width: 20px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 15px;
}

.settings-select__arrow {
	display: flex;
	justify-content: center;
	align-items: center;
}

.settings-select__indicator-separator {
	display: none !important;
}

.settings-select__control.settings-select__control--menu-is-open
	.settings-select__indicators
	svg {
	transform: rotate(180deg);
}

.settings-list.active {
	height: auto !important;
	width: 410px;

	display: flex;
	flex-direction: column;
	gap: 15px;

	.setting-select {
		margin-bottom: 0;
	}

	.close {
		background: none;
		border: none;
		position: absolute;
		right: 10px;
		top: 10px;

		@media (max-width: 768px) {
			right: 5px;
			top: 5px;
		}

		svg {
			width: 20px;
			height: 20px;

			@media (max-width: 768px) {
				width: 15px;
				height: 15px;
			}
		}
	}
}

@media (max-width: 768px) {
	.multi-select-search__multi-value__label {
		font-size: 12px !important;
		line-height: 16px !important;
	}
}

@media (max-width: 480px) {
	.select-block {
		margin-bottom: 15px;
	}

	.select-placeholder {
		font-size: 12px;
		font-weight: 400;
		line-height: 14px;
	}

	.multi-select-stroke {
		span {
			font-size: 12px;
		}
	}

	.multi-select-stroke__multi-value__label {
		font-size: 12px !important;
		font-weight: 400 !important;
		line-height: 14px !important;
	}

	.multi-select-stroke__value-container {
		font-size: 12px !important;
		line-height: 14px !important;
		min-height: 50px;
		padding-left: 13px !important;

		.multi-select-stroke__multi-value {
			margin-left: 0;
		}
	}

	.multi-select-stroke__option {
		font-size: 12px !important;
		line-height: 14px !important;
	}

	.multi-select-stroke__control {
		min-height: 50px !important;
	}

	.select-block p {
		font-size: 13px;
		line-height: 19px;
	}

	.multi-select-search__control {
		min-height: 50px !important;
	}

	.multi-select-search__value-container {
		padding-left: 10px !important;
		min-height: 50px !important;
	}

	.multi-select-search__multi-value {
		font-size: 10px;
		line-height: 12px;
		border-radius: 4px !important;
	}

	.multi-select-search__add-btn {
		width: 30px;
		height: 30px;
		margin-right: 10px;
	}

	.inputs-row .select-block {
		flex: 100%;
	}

	.setting-select {
		border-radius: 6px;
	}

	.settings-select__control {
		height: 50px !important;
		border-radius: 6px !important;
	}

	.setting-select .setting-select__placeholder {
		font-size: 10px;
		line-height: 12px;
	}

	.settings-select__option {
		font-size: 10px;
		line-height: 12px;
		padding: 5px 13px;

		img {
			width: 25px;
			height: 15px;
		}
	}

	.settings-select__custom-value {
		font-size: 10px !important;
		line-height: 12px !important;
		padding-top: 0;

		img {
			width: 25px;
			height: 15px;
		}
	}

	.setting-select.active .setting-select__placeholder {
		font-size: 10px !important;
		line-height: 12px !important;
	}

	.settings-select__control.settings-select__control--menu-is-open {
		border-radius: 6px 6px 0 0 !important;
	}

	.settings-select__menu {
		border-radius: 0 0 6px 6px !important;
	}

	.settings-select__value-container {
		padding-left: 14px !important;
	}

	.settings-select__indicators {
		margin-right: 13px;
	}

	.settings-select__placeholder {
		font-size: 14px;
		line-height: 20px;
	}

	.settings-select__single-value {
		font-size: 14px !important;
		line-height: 20px !important;
	}
}
