.inputs-row .select-gender {
    /* border-radius: 10px;
    background: #EBF1F8; */
    max-width: 116px;
    width: 100%;

    /* height: 58px;
    border: none;
    box-sizing: border-box;
    padding-left: 20px;
    color: #333;
    font-family: 'Montserrat';
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    cursor: pointer;
    outline: none; */
}

@media (max-width: 480px) {
    .inputs-row .select-gender {
        max-width: 100%;
        width: 100%;
    }
}