.wrapper {
	max-width: 106px;
	width: 100%;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 10px;
		background: #fff;
		// box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
		cursor: pointer;
		max-width: 106px;
		width: 100%;
		height: 42px;
		transition: all 0.3s;
		color: rgba(51, 51, 51, 0.7);
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;

		&:hover {
			background: #4e41ab;
			color: #fff;
		}
	}
}
