.action-menu,
.employee-menu {
    padding: 15px 10px;
    padding-top: 25px;
    width: max-content;
    position: absolute;
    z-index: 100;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-radius: 10px;
    background: #EBF1F8;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    box-sizing: border-box;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media (max-width:768px) {
        width: 220px;
    }
}

.action-menu {
    top: 100%;
    right: 0;
}

.employee-menu {
    top: 0;
    right: calc(100% + 10px);

    p,
    a {
        font-size: 14px;
        width: 100%;
    }

    .employee-empty {
        display: flex;
        flex-direction: column;
        gap: 9px;

        @media (max-width:768px) {
            padding: 0 15px;
        }
    }

    @media (max-width:768px) {
        top: -10px;
        right: 0px;
        min-height: 180px;
        width: 110%;
        max-width: 220px;
    }
}

.action-menu button,
.employee-menu button {
    text-align: start;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
    font-family: 'Montserrat';
    width: 100%;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 15px;
    box-sizing: border-box;
}

.action-menu button:first-of-type {
    position: absolute;
    top: 5px;
    right: 15px;
    width: auto;
    margin-bottom: 0;
    padding: 0;
    margin: 0;
}


.action-menu button:hover,
.employee-menu button:hover {
    background: #fff;
}

.action-menu button:first-of-type:hover {
    background: none;
    color: #4E41AB;
}

.product-row {
    position: relative;
}

.action-button {
    background-color: white;
    border-radius: 23px;
    box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
    border-radius: 10px;
    /* padding: 0 2%; */
    width: 120px;
    min-height: 40px;
}