.inputs-row .input-submit-block {
	max-width: 210px;
	width: 100%;

	button {
		max-width: 100%;
		border-radius: 10px;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		height: 58px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		transition: all 0.3s;

		&:hover {
			background: #4e41ab;
		}
	}
	span {
		display: block;
		margin: 10px;
		font-size: 12px;
		color: red;
		line-height: 100%;
	}
}

@media (max-width: 480px) {
	.inputs-row .input-submit-block {
		button {
			max-width: 100%;
			font-size: 13px;
			line-height: 16px;
			height: 40px;
		}
		span {
			margin: 5px;
		}

		max-width: 100%;
		margin-bottom: 15px;
	}
}
