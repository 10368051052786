.inputs-row {
	.select {
		flex: 0 0 50%;
		@media (max-width: 480px) {
			flex: 0 0 100%;
		}
	}
	.time-picker {
		display: flex;
		width: 100%;
		align-items: center;
		gap: 10px;
	}

	.remove-row {
		@media (max-width: 480px) {
			right: -15px;
		}
	}
}
