.header {
  min-width: 100%;
  height: 70px;

  /* min-height: 70px;
      max-height: 70px; */
  position: relative;
  background-color: white;
  box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
}

.header__nav {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    max-width: 150px;

    svg {
      max-width: 100%;
      height: auto;
    }
  }
}

.logo {
  cursor: pointer;
  max-width: 150px;

  svg {
    max-width: 150px;
    height: auto;
  }
}

.logos {
  display: flex;
  gap: 30px;
}

.icon.menu {
  position: relative;
  z-index: 1;
}

@media (max-width: 480px) {
  .logos {
    display: flex;
    gap: 15px;
  }

  .header__nav .logo {
    max-width: 115px;
  }
}