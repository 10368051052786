* {
	padding: 0;
	margin: 0;
}

html {
	width: 100vw;
	overflow-x: hidden;

	scrollbar-width: none;
	-ms-overflow-style: none;

	scrollbar-width: thin;
	scrollbar-color: #8a7de7 #ebeaed;

	scrollbar-track-color: #ebeaed;
	scrollbar-thumb-color: #8a7de7;

	&::-webkit-scrollbar {
		width: 10px;
	}

	&::-webkit-scrollbar-track {
		background: #ebeaed;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background: #8a7de7;
	}
}

.hidden {
	overflow: hidden !important;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	gap: none;
	background: #f9f9fb;
	overflow: hidden;
}

main {
	width: 100%;
	flex: 1 0 auto;
	position: relative;
	display: flex;
	flex-direction: column;
}

.container {
	width: 100%;
	max-width: 1340px;
	margin: 0 auto;

	&.main {
		max-width: 85vw;
	}
}

.btn {
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none !important;
	transition: color 0.3s ease 0s;
	transition: background 0.3s ease 0s;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 10px;
	background: #8a7de7;
	max-width: 340px;
	width: 100%;
	height: 50px;
	border: none;
	color: #fff;
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 500;
	line-height: 24px;

	&:hover {
		color: #fff;
		background: #4e41ab;
	}

	&:disabled {
		cursor: default;
	}
}

button {
	cursor: pointer;
}

input {
	&::-webkit-outer-spin-button {
		-webkit-appearance: none;
	}

	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}
}

input[type='number'] {
	-moz-appearance: textfield;
}

.menu-list {
	display: none;
	position: absolute;
	background: white;
	top: 110px;
	left: 6%;
	width: 200px;
	height: 200px;

	li {
		list-style: none;
		width: 100%;
		white-space: nowrap;
		border-bottom: 1px solid rgba(218, 205, 255, 0.5);
		padding-bottom: 2%;

		p {
			cursor: pointer;
			text-decoration: none;
			color: #222;
			font-size: 15px;
			font-style: normal;
			font-weight: 500;
		}

		a {
			cursor: pointer;
			text-decoration: none;
			color: #222;
			font-size: 15px;
			font-style: normal;
			font-weight: 500;
		}
	}
}

.profile-list {
	display: none;
	position: absolute;
	background: white;
	top: 110px;
	right: 6%;
	width: 200px;
	height: 100px;

	li {
		list-style: none;
		width: 100%;
		white-space: nowrap;
		border-bottom: 1px solid rgba(218, 205, 255, 0.5);
		padding-bottom: 2%;

		p {
			cursor: pointer;
			text-decoration: none;
			color: #222;
			font-size: 15px;
			font-style: normal;
			font-weight: 500;
		}
	}
}

.settings-list {
	display: none;
	position: absolute;
	background: white;
	top: 110px;
	left: 10%;
	width: 200px;
	height: 200px;

	li {
		list-style: none;
		width: 100%;
		white-space: nowrap;
		border-bottom: 1px solid rgba(218, 205, 255, 0.5);
		padding-bottom: 2%;
		border-bottom: none !important;
	}

	p {
		cursor: pointer;
		text-decoration: none;
		color: #222;
		font-size: 15px;
		font-style: normal;
		font-weight: 500;
	}

	a {
		color: #8a7de7;
		text-decoration: none !important;
		transition: all 0.3s;

		&:hover {
			color: #4e41ab;
		}
	}

	select {
		width: 100%;
		margin-top: 5%;
		padding: 3% 2%;
		outline: none;
		border: 1px solid #8a7de7;
		border-radius: 10px;
		font-family: 'Montserrat';
	}
}

.menu-list.active {
	display: block;
	padding: 30px;
	border-radius: 7px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	width: 230px;
	z-index: 99;
	height: 230px;

	ul {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.profile-list.active {
	display: block;
	padding: 30px;
	border-radius: 7px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	width: 230px;
	z-index: 99;
	height: 130px;

	ul {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.settings-list.active {
	display: block;
	padding: 30px;
	border-radius: 7px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	width: 230px;
	z-index: 99;
	height: 300px;
	width: 300px;

	ul {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
}

.header-overlay {
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 21, 0.4);
	z-index: 98;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.static-info {
	border: 1px solid #dee2e6 !important;
	border-radius: 15px;
	padding: 1rem;
	margin-bottom: 1rem;

	p {
		margin-bottom: 1rem;
	}
}

.common-page__subheader {
	font-size: 18px;
	line-height: 24px;
	display: flex;
	align-items: center;
	color: #787878;
	margin-bottom: 1rem;
}

.common-page__title {
	color: #000;
}

.content__title-main {
	padding-top: 25px;
	padding-bottom: 12px;
	font-size: 24px;
}

@media (max-width: 1920px) {
	.container {
		&.main {
			max-width: 1340px;
		}
	}
}

@media (max-width: 1440px) {
	.container {
		padding: 0 30px;
	}
}

@media (max-width: 1200px) {
	.container {
		padding: 0 20px;
		box-sizing: border-box;
	}
}

@media (max-width: 567px) {
	.container {
		padding: 0 10px;
	}
}

@media (max-width: 480px) {
	.App.hidden {
		overflow: hidden;
		height: 100vh;
	}

	main {
		// min-height: calc(100vh - 80px);
	}

	.profile-list.active {
		padding: 15px;
		height: auto;

		ul {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 15px;
		}
	}

	.btn {
		font-size: 13px;
		line-height: 16px;
		height: 40px;
		border-radius: 6px;
	}

	.settings-list.active {
		max-width: 320px;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		padding: 20px;
	}
}
