.modal {
	background: rgba(0, 0, 21, 0.4);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	opacity: 0;
	transition: opacity 200ms ease-in-out;

	&.hide {
		opacity: 0;
		z-index: -100;
	}

	&.show {
		opacity: 1;
		z-index: 44;
	}
}

.modal__window {
	position: relative;
	margin: 0 auto;
	transform: translateY(-50%);
	top: 50%;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-sizing: border-box;
	padding: 30px;
	max-width: 435px;
	width: 100%;
}

.modal__header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	margin-bottom: 40px;
	gap: 20px;

	p {
		color: #222;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}

	h3 {
		color: #222;
		font-size: 22px;
		font-weight: 500;
		line-height: 28px;
	}
}

.modal__close {
	background: none;
	border: none;
	cursor: pointer;
	outline: none;
	position: relative;
	top: -15px;
	right: -15px;

	@media (max-width: 480px) {
		top: 2px;
		right: 0px;
	}

	path {
		transition: all 0.3s;
	}

	&:hover path {
		stroke: #4e41ab;
	}
}

.modal__form {
	.btn {
		max-width: 100%;
		margin-top: 15px;
	}

	.input-file {
		margin-top: 30px;
		margin-bottom: 20px;

		label {
			max-width: 100%;
		}
	}

	.input-block input.input-code:focus {
		padding-top: 0;
	}

	.textarea-block textarea {
		height: 110px;
		width: 100%;
		max-width: 100%;
		background: none;
	}

	.input-file .file-name {
		margin-bottom: 0;
		margin-top: 10px;
	}
}

.modal-complaint {
	.modal__window {
		max-width: 545px;
	}

	input:focus {
		// padding-top: 0;
	}

	.modal__header {
		margin-bottom: 20px;
	}

	.textarea-block {
		margin-bottom: 30px;

		p {
			margin-bottom: 10px;
			color: #333;
			font-size: 18px;
			font-weight: 400;
			line-height: 26px;
		}
	}
}

.modal-warning {
	.modal__window {
		max-width: 515px;
	}

	.modal__header {
		justify-content: center;
		align-items: center;
		margin-bottom: 35px;
		gap: 0;
		text-align: center;
	}
}

.modal__btns {
	display: flex;
	gap: 15px;
	margin-top: 35px;
}

.modal__text {
	color: #6a6a6b;
	font-size: 18px;
	font-weight: 500;
	line-height: 26px;
}

.partner-modal a {
	text-decoration: none;
	color: inherit;
}

.modal-complaint .modal__text .btn {
	max-width: 100%;
	margin-top: 75px;
}

.modal-warning .modal__text {
	text-align: center;
	color: #222;
	font-size: 16px;
	line-height: 24px;
}

.modal__btns {
	display: flex;
	gap: 15px;
	margin-top: 35px;

	.btn {
		margin-top: 0;
		border: 1px solid #555;
		color: #333;
		transition: all 0.3s;
		background: #fff;

		&:hover {
			color: #fff;
			background: #8a7de7;
			border-color: #8a7de7;
		}
	}
}

.modal-remove .modal__window {
	max-width: 515px;
}

.modal-warning .modal__text .btn {
	max-width: 100%;
	margin-top: 35px;
}

.reviews-form p {
	color: #333;
	font-size: 17px;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 15px;
}

.stars-rating-radio {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 35px;

	p {
		margin-bottom: 0;
		font-size: 15px;
		font-weight: 400;
	}
}

.stars-rating-radio-list {
	display: flex;
	align-items: center;
	gap: 7px;
}

.reviews-form .textarea-block textarea {
	border: none;
	background: #ebf1f8;
	color: #333;
	font-family: 'Montserrat';
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;

	&::placeholder {
		color: #333;
		font-family: 'Montserrat';
		font-size: 15px;
		font-weight: 400;
		line-height: 23px;
	}
}

.contact-page-modal {
	max-width: 950px;
}

.modal__form-row {
	display: flex;
	gap: 70px;
	position: relative;

	&::before {
		content: '';
		background: #ece6ff;
		width: 1px;
		height: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	.settings-select__control {
		max-width: 100% !important;
		background: #ebf1f8 !important;
		height: 50px !important;
	}
}

.modal__form-item {
	p {
		margin-bottom: 10px;
	}

	margin-bottom: 20px;

	input {
		font-family: 'Montserrat';
		box-sizing: border-box;
		padding-left: 20px;
		height: 100%;
		color: #333;
		font-size: 15px;
		font-weight: 400;
		line-height: 18px;
		background: none;
		border: none;
		width: 100%;
		outline: none;
		display: block;
		outline: none;
		z-index: 1;
		position: relative;
		position: relative;
		background: #ebf1f8;
		border-radius: 10px;
		height: 50px;
	}

	.input-date {
		input {
			background: none;
			max-width: 100% !important;
			width: 100% !important;
			height: 50px !important;
		}

		.react-datepicker__input-container {
			max-width: 100% !important;
			width: 100% !important;
			height: 50px !important;
		}

		.react-datepicker-popper {
			z-index: 10;
		}
	}

	.error .input-date input {
		border: 1px solid red !important;
	}

	.input-date .react-datepicker-wrapper {
		max-width: 100% !important;
		width: 100% !important;
	}
}

.modal__form-block {
	flex: 1;

	h3 {
		margin-bottom: 20px;
		color: #222;
		font-size: 18px;
		font-weight: 500;
		line-height: 28px;
	}
}

.contact-page-modal {
	.textarea-block textarea {
		border: none;
		background: #ebf1f8;
	}

	textarea:focus {
		padding-top: 20px;
	}
}

.modal__form-bottom {
	display: flex;
	gap: 20px;

	.input-file {
		flex: 1;
		width: auto;
		margin-top: 0;
		margin-bottom: 0;
	}

	.btn {
		flex: 1;
		width: auto;
		margin-top: 0;
	}
}

@media (max-width: 768px) {
	.modal__window {
		width: 90%;
	}

	.contact-page-modal {
		max-height: 500px;
		overflow-y: auto;
	}

	.modal__form-row {
		flex-direction: column;
		gap: 0;

		&::before {
			display: none;
		}
	}

	.contact-page-modal::-webkit-scrollbar {
		width: 4px;
	}
}

@media (max-width: 480px) {
	.modal__window {
		padding: 15px;
		border-radius: 6px;
	}

	.modal__form {
		.input-block {
			min-height: 50px;

			input {
				min-height: 50px;
				padding-left: 13px;
			}
		}

		.input-file {
			margin-top: 0;
			margin-bottom: 25px;
		}
	}

	.modal__close svg {
		width: 25px;
		height: auto;
	}

	.modal__header {
		margin-bottom: 25px;
	}

	.modal-code {
		.inputs-row {
			gap: 15px;
			flex-wrap: nowrap;
			justify-content: center;
		}

		.modal__form {
			.inputs-row .input-block {
				flex: none;
				width: 40px;
				height: 40px;
				min-height: 40px;
			}

			.input-block {
				input {
					height: 40px;
					min-height: 40px;
				}

				margin-bottom: 10px;
			}
		}
	}

	.modal__header h3 {
		font-size: 16px;
		line-height: 20px;
	}

	.modal__text {
		font-size: 14px;
		line-height: 20px;
	}

	.modal-complaint .modal__text .btn {
		margin-top: 30px;
	}

	.partner-page .modal-complaint .modal__window {
		top: initial;
		transform: translateY(0);
		bottom: 0;
		position: fixed;
		width: 100%;
	}

	.modal-complaint .textarea-block p {
		font-size: 14px;
		line-height: 18px;
	}

	.partner-page .modal-complaint .textarea-block textarea {
		height: 40px;
		border-radius: 6px;
		padding: 5px;

		&:focus {
			padding-top: 10px !important;
		}
	}

	.reviews-form p {
		font-size: 14px;
		line-height: 18px;
	}

	.modal__form-block {
		h3 {
			font-size: 14px;
			line-height: 20px;
		}

		.input-date {
			width: 100%;
		}

		input::placeholder,
		textarea::placeholder,
		input,
		textarea {
			font-size: 14px;
			line-height: 20px;
		}
	}

	.modal__form-bottom {
		flex-direction: column;
		justify-content: flex-start;

		.btn {
			height: 40px;
			flex: auto;
		}

		.input-file {
			margin-bottom: 0;
		}
	}
}
