.report-page {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	padding-bottom: 100px;
	box-sizing: border-box;

	.landing-banner {
		margin-top: 20px;
	}

	.landing-banner__img {
		height: 503px;
		@media (max-width: 1024px) {
			height: auto;
		}
		img {
			position: absolute;
			right: 00px;
			top: -20px;

			@media (max-width: 1024px) {
				position: static;
			}
		}
	}

	.landing-banner__block {
		height: auto;
		padding: 45px 35px;
		@media (max-width: 1024px) {
			flex-direction: column;
			gap: 20px;

			.landing-banner__info {
				max-width: 100%;
			}
		}

		@media (max-width: 768px) {
			padding: 30px 20px;
		}
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 5px;
	}

	.emptyproducts-message {
		margin-top: 20px;
		text-align: center;
		color: #8a7de7;
		width: 100%;
		padding: 1% 1%;
		font-weight: 500;
		font-size: 120%;
		border-radius: 10px;
		border: 1px solid #f6f6f6;
		background: #f8f8fa;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.15);
	}

	.distributor-update {
		margin-top: 5px;
		font-size: 10px;
		color: gray;
	}

	.auth-message {
		padding: 2%;
		width: 100%;
		text-align: left;
		font-size: 120%;
	}

	.product-page-currencies {
		align-self: flex-start;
		display: flex;
		align-items: center;
		border-radius: 5px;
		border: 1px solid #dbdbdb;
		width: 180px;
		height: 40px;

		p {
			text-align: center;
			width: 50%;
			color: #555;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;

			&:first-of-type {
				border-right: 1px solid #dbdbdb;
			}
		}
	}

	.product-searchbar {
		max-width: 1100px;
		width: 100%;
		margin: 0 auto;
		height: 70px;
		margin-top: 70px;
		height: auto;

		@media (max-width: 1440px) {
			margin-top: 50px;
		}
		@media (max-width: 768px) {
			margin-top: 41px;
		}

		form {
			display: flex;
			justify-content: flex-end;
			width: 100%;
			height: 100%;
			border-radius: 10px;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		}

		.product-searchbar-block input {
			height: 66px;
			@media (max-width: 1440px) {
				height: 62px;
			}
			@media (max-width: 1024px) {
				height: 51px;
			}
			@media (max-width: 768px) {
				height: 41px;
			}
		}
	}

	.product-searchbar-block {
		height: 100%;
		position: relative;
		width: 100%;

		input {
			width: 100%;
			height: 100%;
		}

		.button-green {
			max-width: 310px;
			background-color: #1fbd54;
			color: #fff;
			border: none;
			outline: none;
			padding: 21px 10px;
			border-radius: 10px;
			font-size: 18px;
			margin-top: 20px;
			line-height: 20px;
			height: auto;

			@media (max-width: 1440px) {
				padding: 20px 10px;
				font-size: 17px;
			}
			@media (max-width: 1025px) {
				padding: 15px 10px;
				font-size: 14px;
			}
			@media (max-width: 768px) {
				max-width: 100%;
				height: 41px;
			}
		}
	}

	.product-searchbar {
		input {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			font-family: 'Montserrat';
			flex-grow: 1;
			box-sizing: border-box;
			padding-left: 30px;
			padding-right: 30px;
			color: #222;
			border-radius: 10px;

			&::placeholder {
				color: #222;
				font-family: 'Montserrat';
				font-size: 16px;
				font-weight: 400;
			}

			border: none;
			outline: none;
		}

		button {
			border: none;
			outline: none;
		}

		.product-searchbar-button:nth-child(3) {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
	}

	.product-searchbar-button {
		cursor: pointer;
		background: #8a7de7;

		/* transition: all 0.3s; */
		box-sizing: border-box;
		width: 75px;
		display: flex;
		justify-content: center;
		align-items: center;
		outline: none !important;

		&.mini {
			width: 56px;
		}

		&:nth-of-type(1) {
			position: relative;
			box-sizing: border-box;

			@media (max-width: 480px) {
				.partners-container {
					padding-top: 130px;
				}

				.partnerrequest-button {
					margin-top: 35px;
				}
			}

			&::before {
				content: '';
				width: 1px;
				height: 48px;
				background: #b1a8f5;
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&:hover {
			background: #4e41ab;
		}
	}

	.product-searchbar-buttons {
		max-width: 88px;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		background-color: #fff;
	}

	.settings-mob {
		display: none;
	}

	.product-searchbar-sort {
		/* transition: all 0.3s ease 0s; */
		background: #fff;
		position: absolute;
		right: 23px;
		top: 50%;

		/* transform: translateY(-50%) rotate(180deg); */
		transform: translateY(-50%);

		&.default {
			transform: translateY(-50%) rotate(180deg);
		}
	}

	.product-searchbar-sort--reverse {
		transform: translateY(-50%) rotate(180deg);
	}

	.product-searchbar-sort {
		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}

	.product-table {
		width: 100%;
		height: 100%;
	}

	.projectpage-table {
		width: 100%;
		height: 100%;
		margin-top: 80px;
	}

	.product-table {
		margin-top: 80px;
		overflow: auto;

		table {
			background: #f8f8fa;
			border-collapse: separate;
			border-spacing: 0 3px;
			margin: 0;
			padding: 0;
			width: 100%;
			table-layout: fixed;
			position: relative;
		}

		th.cursor {
			cursor: pointer;
			transition: all 0.3s ease 0s;

			.flex {
				display: flex;
				align-items: center;
				gap: 5px;
			}
			&.asc,
			&.desc {
				svg {
					fill: #8a7de7;
				}
			}

			&.asc {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		&::-webkit-scrollbar {
			height: 22px;
		}

		&::-webkit-scrollbar-track {
			background: #dacdff80;
			border-radius: 20px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #8a7de7;
			border-radius: 20px;
			border: 3px solid #dacdff;
		}

		table {
			thead tr {
				height: 45px;
				border-radius: 5px;
				border: 1px solid #eee;
				background: #f2f2f2;
				box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
				font-size: 12px;

				th {
					font-weight: normal;
				}
			}

			tbody tr {
				font-size: 12px;
				background: white;
				box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
				transition: all 0.3s;
			}

			thead tr th {
				border-top: 1px solid #eee;
				border-bottom: 1px solid #eee;
			}

			tbody tr td {
				border-top: 1px solid #eaf0f7;
				border-bottom: 1px solid #eaf0f7;
			}

			thead tr th:first-of-type {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				border-left: 1px solid #eee;
			}

			tbody tr td:first-of-type {
				border-top-left-radius: 10px;
				border-bottom-left-radius: 10px;
				border-left: 1px solid #eaf0f7;
			}

			thead tr th:last-of-type {
				border-bottom-right-radius: 5px;
				border-top-right-radius: 5px;
				border-right: 1px solid #eee;
			}

			tbody tr {
				td:last-of-type {
					border-bottom-right-radius: 10px;
					border-top-right-radius: 10px;
					border-right: 1px solid #eaf0f7;
				}

				&:hover {
					background: #e5e9fb;
				}
			}

			tr {
				background-color: #f8f8f8;
				border: 1px solid #ddd;
			}

			tbody tr {
				height: 77px;
			}

			th,
			td {
				padding: 15px 20px;
				text-align: left;
				box-sizing: border-box;
			}
		}
	}

	.tolist-button:last-of-type:hover {
		background: #4e41ab;
	}

	.country-col {
		width: 80px;
		padding-left: 1.1em !important;
		padding-right: 0.9em !important;

		&-flag {
			height: 16px;
			width: 30px;
		}

		div {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
		}
	}

	.article-col {
		width: 10vw;
		padding-left: 0.7em !important;
		padding-right: 0.4em !important;

		&-name {
			max-width: 200px;
			width: 100%;
			min-width: 100px;

			@media screen and (max-width: 1400px) {
				width: 100px;
			}
		}
	}

	.name-col {
		max-width: 600px;
		width: 100%;
		padding-left: 0.1em;
		padding-right: 0.1em;
		position: relative;
		padding: 0 !important;
	}

	/* .name-col p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
} */

	.currency-col {
		width: 85px;
		padding-left: 0.7em !important;
		padding-right: 0.9em !important;
	}

	thead .currency-col:not(.projectpage-table .currency-col) {
		font-weight: 500 !important;
	}

	.available-col,
	.instock-col,
	.transit-col {
		width: 150px;
		padding-left: 0.7em !important;
		padding-right: 0.9em !important;
	}

	.large-size {
		p {
			font-size: 25px;
		}
	}

	.manufacturer-col {
		width: 130px;
		padding-left: 0;
		padding-right: 0;
	}

	.manufacturer-col-wrap {
		display: flex;
		align-items: center;
		gap: 10px;

		.input-note {
			position: relative;
			right: auto;
			top: auto;
			transform: none;
		}

		.input-note-desc {
			right: auto;
			left: 30px;
		}
	}

	.manufacturer-col {
		position: relative;
		width: 140px;

		&.not-authorized {
			width: 215px;
		}

		div {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 5%;
		}

		.blur {
			border-radius: 0px 10px 10px 0px;
			background: rgba(255, 255, 255, 0.3);
			filter: blur(4px);
			transition: all 0.3s;
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.btn-register {
			border-radius: 0px 10px 10px 0px;
			background: #8a7de7;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			position: absolute;
			right: 0;
			top: 0;
			height: 100%;
			width: 100%;
			opacity: 0;
			transition: all 0.3s;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: left;
			box-sizing: border-box;
			padding: 0 15px;
			color: #fff !important;
			font-size: 12px;
			font-weight: 500;
			text-decoration: none !important;

			&.radius-zero {
				border-radius: 0;
			}
		}

		.blur:hover {
			filter: blur(0px);

			.btn-register {
				opacity: 1;
			}
		}
	}

	.btn-register:hover {
		background: #4e41ab;
	}

	.tolist-col {
		width: 120px;
		padding-left: 0.7em !important;
		padding-right: 0.9em !important;
	}

	tbody {
		.available-col {
			color: #8a7de7;
		}

		.instock-col {
			color: #f00;
		}

		.transit-col {
			color: #333;
		}
	}

	.article-col {
		word-wrap: break-word;
		white-space: pre-wrap;
	}

	.manufacturer-col a {
		word-wrap: break-word;
		white-space: pre-wrap;
		text-decoration: none;
		color: #0139ff;
		width: 100%;
		transition: all 0.3s;
		padding-right: 10px;

		&:hover {
			color: #4e41ab;
		}
	}

	.tolist-button {
		width: 100%;
		height: 100%;
		background-color: #8a7de7;
		color: white;
		text-align: center !important;
		cursor: pointer;
		transition: all 0.3s;

		.blur {
			height: 100%;
			background-color: inherit;
		}

		p {
			height: 100%;
			width: 100%;
		}

		.blur p {
			height: auto;
			width: 100%;
		}
	}

	.next-previous-page {
		padding-top: 60px;
		display: flex;
		gap: 5%;
		display: flex;
		justify-content: center;
		position: relative;

		@media (max-width: 567px) {
			padding-top: 50px;
		}

		button {
			min-height: 50px;
			max-width: 250px;
			width: 100%;
			border: none;
			border-radius: 23px;
			background: #fff;
			color: white;
			box-shadow: 0px 2px 10px rgba(12, 11, 11, 0.05);
			border-radius: 10px;
			font-family: 'Montserrat';
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			transition: all 0.3s;
			color: #333;
			font-weight: 500;
			border: 1px solid #555;
			cursor: pointer;

			path {
				transition: all 0.3s;
			}

			&:hover {
				background: #4e41ab;
				border-color: #4e41ab;
				color: #fff;

				path {
					stroke: #fff;
				}
			}

			&.disabled {
				cursor: not-allowed;

				&:hover {
					background: #fff;
					cursor: not-allowed;
					color: #333;

					path {
						stroke: #333;
					}
				}
			}
		}
	}

	.sidebar {
		width: 420px;
		height: 100%;
		position: fixed;
		top: 0;
		right: -150%;
		background-color: white;
		overflow-x: hidden;
		transition: 0.5s;
		z-index: 9999;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: #ebeaed;
			border-radius: 4px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background: #8a7de7;
		}

		&.open {
			right: 0;
		}
	}

	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 21, 0.4);
		z-index: 1;
		transition: opacity 200ms ease-in-out;
		opacity: 0;
		z-index: -100;

		&.open {
			opacity: 1;
			z-index: 44;
		}
	}

	.sidebar-nav {
		padding: 30px 30px 50px 30px;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		gap: 15px;
	}

	.sidebar-nav-filter-header {
		font-weight: 500;
		font-size: 16px;
		cursor: pointer;
		display: flex;
		gap: 7px;
		margin-bottom: 13px;
		color: #222;

		i {
			transform: rotate(0deg);
			display: flex;
			align-items: center;
			justify-content: center;
			position: relative;
		}

		&.opened i {
			transform: rotate(180deg);
			display: flex;
			position: relative;
		}
	}

	.sidebar-nav .filter-apply {
		display: flex;
		justify-content: center;
		align-items: center;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		background: #8a7de7;
		border-radius: 10px;
		box-sizing: border-box;
		height: 50px;
		padding: 0 30px;
		cursor: pointer;
		border: none;
		transition: all 0.3s;
		color: #fff;
		font-size: 15px;
		font-family: 'Montserrat';
		font-weight: 500;
		line-height: 20px;
		margin-top: 35px;
		min-width: 200px;

		&:hover {
			background: #4e41ab;
		}
	}

	/* .sidebar-nav-filter-header.opened::before {
    content: url('../../assets/Opened.png');
    margin-right: 10px;
    margin-top: 10px;
    width: 16px;
    height: 36px;
    cursor: pointer;
}

.sidebar-nav-filter-header.closed::before {
    content: url('../../assets/Closed.png');
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
} */

	.sidebar-close {
		background: none;
		border: none;
		cursor: pointer;
		width: 35px;
		height: 35px;
		margin-left: auto;
		transition: all 0.3s;

		path {
			transition: all 0.3s;
		}

		&:hover path {
			stroke: #4e41ab;
		}
	}

	.sidebar-nav-options {
		padding-left: 45px;
		display: flex;
		gap: 10px;
		cursor: pointer;
		margin-bottom: 15px;
		box-sizing: border-box;
		color: #333;

		&:last-child {
			margin-bottom: 0;
		}

		input {
			accent-color: #8a7de7;
		}
	}

	.products-mob,
	.searchbar-buttons-modal {
		display: none;
	}

	.clamp-lines > span {
		word-break: break-all;
		display: block;
	}
	.collapsible-text {
		padding: 12px 10px;
		font-weight: 400 !important;
		color: #000;

		&-span {
			word-wrap: break-word;
			font-weight: 400 !important;

			span {
				font-weight: 400 !important;
			}

			.collapse-button {
				font-weight: 600 !important;
			}
		}

		p {
			display: inline;

			&.truncate {
				margin: 0;
				/* Убираем внешний отступ */
				-webkit-line-clamp: 3;
				/* Число отображаемых строк */
				display: -webkit-box;
				/* Включаем флексбоксы */
				-webkit-box-orient: vertical;
				/* Вертикальная ориентация */
				overflow: hidden;
			}
		}

		.collapse-button,
		.clamp-lines__button {
			background: none;
			border: none;
			cursor: pointer;
			color: #8a7de7;
			transition: all 0.3s;
			font-family: 'Montserrat';
			font-size: 14px;
			font-weight: 600;
			line-height: 19px;
			display: inline-block;
			display: flex;

			&:hover {
				color: #4e41ab;
			}
		}

		&.open {
			position: relative;

			/* left: 0;
        top: 0; */
			height: 100%;
			width: 100%;
			border-radius: 10px;
			// border: 1px solid #e5ebf2;
			// background: #d7e7f9;
			// box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			box-sizing: border-box;
			padding: 12px 10px;
			z-index: 5;
			padding-left: 0;
		}
	}

	@media screen and (max-width: 1920px) {
		.article-col {
			max-width: 120px;
		}
	}
	@media screen and (max-width: 1024px) {
		.product-table {
			table {
				padding: 0;
				margin: 0;
				border-spacing: 0 20px;
				border: 0;

				thead {
					border: none;
					clip: rect(0 0 0 0);
					height: 1px;
					margin: -1px;
					overflow: hidden;
					padding: 0;
					position: absolute;
					width: 1px;
				}

				tr {
					border-bottom: 3px solid #ddd;
					margin-bottom: 0.625em;
					background: none !important;
					box-shadow: none !important;
				}

				tbody tr td {
					border-top: none;
					border-bottom: none;
				}

				td {
					background: white;
					border-bottom: 1px solid #ddd;
					display: block;
					font-size: 12px;
					text-align: right;

					&::before {
						content: attr(aria-label);
						float: left;
						font-weight: normal;
						background-color: white;
						height: max-content;
						width: 20%;
						text-align: left;
					}

					&:last-child {
						border-bottom: 0;
					}
				}

				tbody tr td:first-of-type {
					border-top-left-radius: 0px !important;
					border-bottom-left-radius: 0px !important;
					border-left: none;
				}

				td {
					padding: 0;
				}
			}

			.country-col,
			.article-col,
			.name-col,
			.currency-col,
			.available-col,
			.instock-col,
			.transit-col,
			.manufacturer-col,
			.tolist-col {
				width: auto !important;
				padding-left: 0.7em !important;
				padding-right: 0 !important;
			}

			.country-col div,
			.article-col p,
			.name-col p,
			.currency-col p,
			.available-col p,
			.instock-col p,
			.transit-col p,
			.manufacturer-col div {
				background: #f8f8fa;
				padding-left: 10px;
				padding-bottom: 20px;
			}

			.name-col p {
				display: block;
			}

			.country-col div {
				width: 80%;
				height: 100%;
				flex-direction: row-reverse;
				align-items: center;
				justify-content: flex-end;
				gap: 1%;
				background: #f8f8fa;
			}

			.article-col p,
			.name-col p,
			.currency-col p,
			.available-col p,
			.instock-col p,
			.transit-col p {
				text-align: left;
				margin-left: 20%;
				height: 100%;
			}

			.manufacturer-col {
				a {
					width: auto;
				}

				div {
					width: 80%;
					justify-content: flex-start;
					gap: 1%;
				}
			}

			.tolist-col {
				background: white;
				padding: 0;

				p {
					margin-left: 20% !important;
					height: 100%;
					background: #8a7de7;
					padding: 0.625em 0 !important;
				}
			}

			.tolist-button {
				color: black;
			}
		}

		.sidebar.open {
			width: 100%;
		}

		/* новая таблица для моба */

		.product-table {
			display: none;
		}

		.products-mob {
			display: block;
			margin-top: 60px;
		}

		.products-mob-item {
			margin-bottom: 20px;
		}

		.products-mob-item__row {
			display: flex;
			gap: 10px;
		}

		.products-mob-item__col-name {
			width: 55px;
			background: #fff;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
			box-sizing: border-box;
			padding: 5px;
			color: #222;
			font-size: 7px;
			font-weight: 400;
			line-height: 11px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		.products-mob-item__row {
			&:first-child .products-mob-item__col-name {
				border-radius: 3px 3px 0 0;
			}

			&:last-child .products-mob-item__col-name {
				border-radius: 0 0 3px 3px;
			}
		}

		.products-mob-item__col-value {
			position: relative;
			border-bottom: 1px solid #eaf0f7;
			padding-bottom: 10px;
			padding-top: 10px;
			width: 100%;
			color: #333;
			font-size: 10px;
			font-weight: 400;
			line-height: 11px;
			flex: 1;
			overflow: hidden;

			p {
				color: #333;
				font-size: 10px;
				font-weight: 400;
				line-height: 11px;
				word-break: break-all;
			}

			.blur {
				border-radius: 3px;
				background: rgba(255, 255, 255, 0.3);
				filter: blur(4px);
			}

			.btn {
				width: 138px;
				height: 30px;
				text-align: center;
				box-sizing: border-box;
				pad: 0 15px;
				color: #fff;
				font-size: 7px;
				font-weight: 500;
				border-radius: 4px;
				box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
				line-height: 10px;
			}
		}

		.products-mob-item__row:first-child .products-mob-item__col-value {
			display: flex;
			align-items: center;
		}

		.products-mob-item__col-value {
			.code {
				margin-right: 10px;
				margin-left: 3px;
			}

			.flag {
				width: 18px;
				height: 12px;
			}
		}

		.products-mob-item__row:last-child .products-mob-item__col-value {
			display: flex;
			justify-content: space-between;
			align-items: center;

			.price {
				display: flex;
				align-items: center;
				gap: 2px;
			}

			.tolist-button {
				width: 138px;
				height: 27px;
				border-radius: 4px;
				box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
				text-align: center;
				font-size: 8px;
				font-weight: 500;
				line-height: 11px;
				display: flex;
				justify-content: center;
				align-items: center;

				p {
					display: flex;
					display: flex;
					justify-content: center;
					align-items: center;
					color: #fff;
				}
			}
		}

		.products-mob-item__col-value .distributor {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 10px;
			font-weight: 400;
			line-height: 11px;
			position: relative;
			padding-left: 10px;

			&-relative {
				position: relative;
				display: inline;
				width: 20px;
				height: 15px;
			}

			a {
				text-decoration: none;
				color: #0139ff;
			}
		}
	}

	@media screen and (max-width: 480px) {
		.product-searchbar {
			margin-top: 20px;
			height: auto;

			input {
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
				box-sizing: border-box;
				padding-left: 15px;
				padding-right: 15px;
				font-size: 9px;
				font-weight: 400;
				line-height: 11px;
				letter-spacing: -0.09px;

				&::placeholder {
					font-size: 9px;
					font-weight: 400;
					line-height: 11px;
					letter-spacing: -0.09px;
				}
			}
		}

		.settings-mob {
			display: flex;
		}

		.product-searchbar-buttons .product-searchbar-button {
			&:nth-child(2),
			&:nth-child(3) {
				display: none;
			}
		}

		.product-searchbar-block .product-searchbar-sort {
			display: none;
		}

		.product-searchbar-button {
			width: 50%;
		}

		.product-searchbar-buttons {
			width: 95px;
			max-width: 100%;
			max-width: 85px;
			width: 100%;
		}

		.product-searchbar-button:nth-of-type(1)::before {
			height: 35px;
		}

		.product-searchbar-buttons svg {
			width: 23px;
			height: auto;
		}

		.product-searchbar {
			position: relative;
		}

		.searchbar-buttons-modal {
			position: absolute;
			right: 0;
			top: 100%;
			box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
			border-radius: 5px;
			border: 1px solid #f6f6f6;
			background: #fff;
			max-width: 195px;
			width: 100%;
			box-sizing: border-box;
			padding: 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 25px;
			margin-top: 20px;
			opacity: 0;
			transition: opacity 200ms ease-in-out;
			z-index: -100;

			&.show {
				opacity: 1;
				z-index: 45;
			}
		}

		.product-searchbar-button.reverse {
			transform: rotate(180deg);
		}

		.searchbar-buttons-modal .product-searchbar-button {
			width: 35px;
			height: 35px;
			border: 1px solid #8a7de7;
			border-radius: 3.5px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: none;

			svg {
				width: 17px;
				height: auto;
			}

			&:nth-of-type(1)::before {
				display: none;
			}
		}

		.product-searchbar .product-searchbar-button:last-of-type {
			border-top-right-radius: 5px;
			border-bottom-right-radius: 5px;
		}

		.searchbar-buttons-modal .product-searchbar-button:last-of-type {
			border-radius: 3.5px;
		}

		.product-searchbar-button:hover {
			background: #8a7de7 !important;
		}

		.searchbar-buttons-modal .product-searchbar-button:hover path {
			stroke: #fff;
		}

		.index-top * {
			z-index: 999;
		}

		.settings-mob.active path {
			stroke: #4e41ab;
		}

		.next-previous-page button {
			max-width: 100%;
			height: 35px;
			font-size: 12px;
			line-height: 12px;

			svg {
				width: 13px;
				height: auto;
			}
		}

		.product-page-currencies {
			p {
				font-size: 13px;
				font-weight: 500;
				line-height: 19px;
			}

			width: 135px;
			height: 33px;
			border-radius: 3px;
		}

		.product-page {
			padding-bottom: 60px;
		}

		.sidebar-nav-filter-header {
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 10px;
		}

		.sidebar-nav {
			padding: 20px;
		}

		.sidebar-nav-options {
			font-size: 13px;
			margin-bottom: 10px;
			padding-left: 60px;
		}

		.sidebar-nav .filter-apply {
			max-width: 100%;
			width: 100%;
			margin-top: 15px;
		}
	}

	.collapsed {
		overflow: hidden;
		display: -webkit-box !important;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3; /* Ограничение на 3 строки */
	}

	.expanded {
		display: block; /* Полное отображение текста */
	}
}
