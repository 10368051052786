.auth-container {
	padding: 70px 20px;
	box-sizing: border-box;
}

.auth-block {
	max-width: 563px;
	width: 100%;
	border-radius: 15px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	margin: 0 auto;
	box-sizing: border-box;
	padding: 70px 80px;
}

.auth-block__header {
	margin-bottom: 50px;
	text-align: center;

	h1 {
		margin-bottom: 15px;
		color: #222;
		font-size: 32px;
		font-weight: 500;
		line-height: 40px;
	}

	p {
		color: #333;
		font-size: 18px;
		font-weight: 500;
		line-height: 24px;
	}
}

.reset-password {
	color: #8a7de7;
	font-size: 15px;
	font-weight: 400;
	line-height: 23px;
	transition: all 0.3s;
	position: relative;
	display: inline-block;
	text-decoration: none !important;
	background: none;
	border: none;
	cursor: pointer;
	position: relative;
	width: auto;

	&:hover {
		color: #4e41ab;
	}
}

.form-tooltip {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.auth-block {
	.btn {
		max-width: 100%;
		margin-top: 25px;
		height: 60px;
	}

	.input-block {
		margin-bottom: 20px;
		position: relative;
		background: #ebf1f8;
		border-radius: 10px;
	}

	.input-block input {
		// min-height: 70px;
	}
}

.input-block input::placeholder {
	font-family: 'Montserrat';
	color: #777;
	font-size: 15px;
	font-weight: 400;
	line-height: 18px;

	@media (max-width: 567px) {
		font-size: 12px;
	}
}

.auth-block__or {
	margin-top: 35px;

	p {
		text-align: center;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 10px;
		color: #333;
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;

		&::before,
		&::after {
			content: '';
			display: block;
			flex: 1;
			border-radius: 1px;
			background: #ebf1f8;
			height: 1px;
		}
	}
}

.auth-block-socials {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 35px;
	gap: 20px;
}

.auth-block-social {
	height: 48px;
	width: 100%;
	// filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.03));
	border: 1px solid #757775;
	padding: 5px 10px;
	background: #fff;
	border-radius: 8px;
	overflow: hidden;
	cursor: pointer;

	svg {
		width: 100%;
		height: 100%;
	}
}

.error-message {
	color: red;
	margin-bottom: 10px;
	font-size: 12px;
	list-style: 15px;
}

@media (max-width: 480px) {
	.auth-container {
		padding: 30px 10px;
	}

	.auth-block {
		padding: 30px 25px;
	}

	.auth-block__header {
		h1 {
			font-size: 19px;
			line-height: 27px;
			margin-bottom: 5px;
		}

		p {
			font-size: 11px;
			line-height: 17px;
		}

		margin-bottom: 30px;
	}

	.auth-block {
		.input-block {
			input {
				min-height: 50px;
			}

			margin-bottom: 15px;
			min-height: 50px;
		}

		.input-block {
			border-radius: 6px;
		}
	}

	.reset-password {
		font-size: 11px;
		line-height: 18px;
	}

	.auth-block .btn {
		height: 40px;
		border-radius: 6px;
		margin-top: 20px;
	}

	.auth-block__or {
		margin-top: 25px;

		p {
			font-size: 11px;
			line-height: 15px;
		}
	}

	.auth-block-socials {
		margin-top: 25px;
		gap: 15px;
	}

	.auth-block-social {
		width: 90px;
		height: 35px;
	}
}
