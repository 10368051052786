.new-employee-message {
	font-weight: 500;
	margin: 1% 0;
	color: green;
}

.new-employee-message-error {
	font-weight: 500;
	margin: 1% 0;
	color: red;
}

.employees-container {
	max-width: 922px;
	width: 100%;
}

.employee {
	display: none !important;
}

.employees-header h1,
.new-employee-header h1,
.employees-list-header h1 {
	color: #8a7de7;
	font-size: 26px;
	font-weight: 500;
	line-height: 34px;
}

.employees-header p {
	color: #6a6a6b;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	margin-top: 15px;
}

.info-close,
.ammount-close {
	display: none;
}

.NEH-unactive::after {
	background-image: url('../../../assets/employees-false.png');
}

.NEH-active::after {
	background-image: url('../../../assets/employees-true.png');
	background-size: 25px 25px;
	display: inline-block;
	width: 25px;
	height: 25px;
	content: '';
}

.NEH-unactive::after {
	background-size: 25px 25px;
	display: inline-block;
	width: 25px;
	height: 25px;
	content: '';
}

.employees-header-button {
	display: flex;
	margin-top: 20px;

	&.hidden {
		display: none;
	}

	.btn {
		max-width: 378px;
		width: 100%;
	}
}

.new-employee-info {
	color: #6a6a6b;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	margin-top: 15px;
}

.modal__form .new-employee-info {
	margin-bottom: 15px;
}

.btn-back {
	display: flex;
	justify-content: center;
	align-items: center;
	border: none;
	border-radius: 10px;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	cursor: pointer;
	max-width: 106px;
	width: 100%;
	height: 42px;
	transition: all 0.3s;
	color: rgba(51, 51, 51, 0.7);
	font-family: 'Montserrat';
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	margin-left: auto;

	&:hover {
		background: #4e41ab;
		color: #fff;
	}
}

.new-employee-info-close {
	display: none;
}

.profileuser-form {
	.input-block {
		input {
			// padding-top: 0;
		}
	}
}

.new-employee-form {
	.input-block {
		margin-bottom: 15px;
	}

	input:focus {
		// padding-top: 0;
	}

	.btn {
		height: 60px;
		max-width: 378px;
	}
}

.ammount-open {
	display: flex;
	flex-direction: column;
	margin-top: 40px;
	max-width: 650px;
	width: 100%;

	// .btn {
	//   height: 60px;
	//   max-width: 378px;
	// }

	// input:focus {
	//   padding-top: 0 !important;
	// }
}

.ammount-dropdown-wrapper-btn {
	display: grid;
	grid-template-columns: 1fr 1fr;
	display: flex;
	gap: 10px;

	.btn {
		max-width: 100%;
	}

	.btn-back {
		height: 50px;

		@media (max-width: 480px) {
			height: auto;
		}

		@media (max-width: 567px) {
			display: block;
			margin: 0;
			padding: 0;
		}
	}
}

.new-employee-header {
	margin-top: 60px;
}

.NEH-active {
	position: relative;
	color: #4e41ab !important;
	display: flex;
	align-items: center;
	gap: 15px;
}

.NEH-unactive {
	position: relative;
	color: #6a6a6b !important;
	display: flex;
	align-items: center;
	gap: 15px;
}

.new-employee-close {
	display: none;
}

.new-employee-content {
	margin-top: 20px;
	margin-right: 10px;
	box-sizing: border-box;
	padding-left: 20px;
	padding-top: 20px;
	position: relative;

	&::before {
		content: '';
		border-radius: 1px;
		background: rgba(218, 205, 255, 0.5);
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 100%;
	}
}

.new-employee-form {
	max-width: 650px;
	width: 100%;
}

.employees-list {
	margin-top: 60px;
}

.employees-list-header {
	h1 {
		margin-bottom: 15px;
	}

	p {
		color: #6a6a6b;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
	}

	margin-bottom: 30px;
}

.employees-list-user {
	margin-bottom: 10px;
	background: white;
	border-radius: 13px;
	box-sizing: border-box;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
	padding: 25px 30px;
	display: flex;
	justify-content: space-between;
}

.employees-list-user-info,
.employees-list-user-icons {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
}

.employees-list-user-info > div p:first-of-type {
	color: #333;
	font-size: 18px;
	font-weight: 400;
	line-height: 26px;
	display: flex;
	gap: 7px;
	align-items: center;
}

.indicator {
	width: 7px;
	height: 7px;
	border-radius: 100%;
	background: #f00;

	&.online {
		background: #20bf55;
	}
}

.employees-list-user-info > div p:nth-of-type(2) {
	color: #333;
	font-size: 16px;
	font-weight: 300;
	line-height: 23px;
}

.employees-list-user-icons button {
	background: none;
	border: none;
	cursor: pointer;

	path {
		transition: all 0.3s;
	}

	&:hover path {
		stroke: #4e41ab;
	}
}

.zero-employees-ammount {
	margin: 2% 0;
	font-size: 120%;
	color: #8a7de7;
	font-weight: 600;
}

.employees-list .btn {
	max-width: 378px;
	margin-top: 10px;
	height: 50px;
}

@media (max-width: 480px) {
	.employees-header h1,
	.new-employee-header h1,
	.employees-list-header h1 {
		font-size: 17px;
		line-height: 23px;
	}

	.employees-container {
		padding-top: 15px;
	}

	.employees-header p {
		font-size: 11px;
		line-height: 17px;

		br {
			display: none;
		}
	}

	.employees-header-button .btn {
		height: 40px;
	}

	.employees-container .btn-back {
		justify-content: center;
	}

	.ammount-open {
		.btn {
			height: 40px;
		}

		margin-top: 15px;
	}

	.new-employee-info {
		font-size: 11px;
		line-height: 17px;
	}

	.new-employee-header {
		margin-top: 35px;
	}

	.new-employee-content {
		padding: 0;

		&::before {
			display: none;
		}
	}

	.new-employee-form .btn {
		height: 40px;
	}

	.employees-list {
		margin-top: 35px;

		.btn {
			max-width: 100%;
		}
	}

	.employees-list-user {
		padding: 13px 10px;
		border-radius: 7px;
	}

	.employees-list-user-info {
		> div p {
			&:first-of-type {
				font-size: 11px;
				line-height: 18px;
			}

			&:nth-of-type(2) {
				font-size: 10px;
				line-height: 17px;
			}
		}

		svg {
			width: 29px;
			height: 29px;
		}
	}

	.employees-list-user-icons svg {
		width: 17px;
		height: 17px;
	}

	.employees-list-user-info,
	.employees-list-user-icons {
		gap: 10px;
	}

	.employees-list-user-info,
	.employees-list-user-icons {
		gap: 5px;
	}

	.indicator {
		width: 5px;
		height: 5px;
	}

	.employees-list-header {
		p {
			font-size: 11px;
			line-height: 17px;
		}

		margin-bottom: 20px;
	}

	.employees-list .btn {
		height: 40px;
	}
}
