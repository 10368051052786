$break_lg: 1366px;
$break_md: 1180px;
$break_sm: 768px;
$break_xs: 500px;
:root {
	--mobile: 600px;
	--tablet: 900px;
	--desktop: 901px;
}

.container {
	// background-color: blue;
}

@media (max-width: var(--mobile)) {
	.container {
		background-color: red;
	}
}

@media (max-width: var(--tablet)) {
	.container {
		background-color: green;
	}
}

@media (min-width: var(--desktop)) {
	.container {
		background-color: yellow;
	}
}

//Media queries
@mixin lg-block() {
	@media screen and (max-width: $break_lg) {
		@content;
	}
}

@mixin md-block() {
	@media screen and (max-width: $break_md) {
		@content;
	}
}

@mixin sm-block() {
	@media screen and (max-width: $break_sm) {
		@content;
	}
}

@mixin xs-block() {
	@media screen and (max-width: $break_xs) {
		@content;
	}
}
