.partner-page {
	width: 100%;
	padding-top: 60px;
	padding-bottom: 65px;
	display: flex;
	flex-direction: column;
}

.partner-header {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 10px;
}

.partner-header__title {
	display: flex;
	align-items: center;
	gap: 20px;
	flex: 1;

	img {
		max-width: 90px;
		width: 100%;
		height: auto;
	}
}

.partner-logo {
	display: flex;
	align-items: center;
	gap: 10px;
	flex: 1;
}

.partner-header__title {
	h1 {
		color: #222;
		font-size: 36px;
		font-weight: 500;
		line-height: 44px;
	}

	.rating {
		display: flex;
		align-items: center;
		gap: 7px;
		color: #222;
		font-size: 26px;
		font-weight: 400;
	}
}

.btn-back {
	max-width: 106px;
	width: 100%;

	button {
		display: flex;
		justify-content: center;
		align-items: center;
		border: none;
		border-radius: 10px;
		background: #fff;
		// box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
		cursor: pointer;
		max-width: 106px;
		width: 100%;
		height: 42px;
		transition: all 0.3s;
		color: rgba(51, 51, 51, 0.7);
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;

		&:hover {
			background: #4e41ab;
			color: #fff;
		}
	}
}

.supplier-partner {
	margin-top: 50px;

	p {
		margin-bottom: 30px;
		color: #222;
		font-size: 22px;
		font-weight: 400;
		line-height: 30px;
	}
}

.supplier-partner__list {
	display: flex;
	flex-wrap: wrap;
	gap: 20px;
	list-style: none;
	padding: 0;
	margin: 0;
	flex-wrap: wrap;
	overflow: hidden;

	li {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		border: 1px solid #ebf1f8;
		background: #fff;
		width: calc(14% - 15px);
		box-sizing: border-box;
		padding: 20px;
		height: 100px;

		a {
			height: 100%;
			display: flex;
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-decoration: none;
			color: inherit;
			font-size: 18px;
		}

		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.partner-information {
	background: #fff;
	margin: 50px 0;
	padding: 50px 0;
	box-sizing: border-box;
}

.partner-information__row {
	display: flex;
	gap: 50px;
	flex-wrap: wrap;
}

.partner-information__item {
	width: calc(50% - 25px);
	display: flex;
	justify-content: space-between;
	gap: 5px;

	&.flex-end {
		justify-content: flex-end;

		@media (max-width: 992px) {
			justify-content: space-between;
		}

		.partner-contacts-empty {
			flex: 1 1 310px;
			width: 100%;

			@media (max-width: 992px) {
				display: none;
			}
		}

		.partner-contacts {
			flex: 1 1 310px;
			width: 100%;

			@media (max-width: 768px) {
				max-width: 100%;
				flex: 1;
			}
		}
	}

	@media (max-width: 567px) {
		flex-direction: column;
	}

	h2 {
		color: #222;
		font-size: 26px;
		font-weight: 500;
		line-height: 32px;
		padding-bottom: 10px;
		position: relative;
		margin-bottom: 20px;

		&::before {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			border-radius: 1px;
			background: #ebf1f8;
		}
	}
}

.partner-message-client p {
	color: #222;
	font-size: 18px;
	font-weight: 400;
	line-height: 26px;
}

.partner-works-countries {
	flex: 1;
}

.partner-works-countries ul {
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	gap: 20px;
	flex-wrap: wrap;

	display: grid;
	display: flex;
	flex-wrap: wrap;

	grid-template-columns: repeat(auto-fit, minmax(195px, 1fr));

	li {
		display: flex;
		align-items: center;
		gap: 10px;
		color: #222;
		font-size: 18px;
		font-weight: 400;
		line-height: 19px;

		label {
			span {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				box-sizing: border-box;
				border-radius: 10px;
				height: 50px;
				padding: 0 10px;
				transition: all 0.3s;
				cursor: pointer;
			}

			input {
				display: none;

				&:checked ~ span {
					background: #8a7de7;
					color: #fff;
				}
			}
		}
	}

	img {
		width: 44px;
		height: 29px;

		@media (max-width: 1200px) {
			width: 31px;
			height: 20px;
		}
		@media (max-width: 1200px) {
			width: 27px;
			height: 18px;
		}
	}
}

.partner-contacts__call {
	margin-top: 30px;
	width: 100%;
}

.partner-contacts__list {
	display: flex;
	gap: 30px;
	flex-wrap: wrap;

	ul {
		flex: 1;
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			gap: 10px;
			display: flex;
			color: #222;
			font-size: 18px;
			font-weight: 400;
			line-height: 24px;
			margin-bottom: 20px;
			align-items: flex-start;

			a {
				text-decoration: none;
				color: inherit;
			}

			&:last-child {
				margin-bottom: 0;
			}

			strong {
				font-weight: 500;
			}

			button {
				color: #8a7de7;
				font-size: 18px;
				font-weight: 500;
				line-height: 24px;
				text-decoration: none !important;
				transition: all 0.3s;
				background: none;
				border: none;
				cursor: pointer;
				outline: none;
			}

			a:hover {
				color: #4e41ab;
			}
		}
	}
}

.partner-contacts__call .btn {
	max-width: 290px;
	height: 60px;
	width: 100%;
}

.partner-questionnaire__row {
	display: flex;
	flex-wrap: wrap;
	gap: 50px;
}

.partner-questionnaire__list {
	flex: 1;
}

.partner-questionnaire-item {
	margin-bottom: 50px;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	&::before {
		content: '';
		border-radius: 1px;
		background: #ebf1f8;
		height: 1px;
		width: 100%;
		position: absolute;
		bottom: -10px;
		left: 0;
	}

	> p {
		padding-bottom: 10px;
		position: relative;
		display: flex;
		gap: 15px;
		color: #222;
		font-size: 20px;
		font-weight: 400;
		line-height: 26px;

		strong {
			font-weight: 500;
		}
	}
}

.partner-questionnaire-item__sup {
	margin-top: 30px;

	p {
		color: #222;
		font-size: 18px;
		font-weight: 400;
		line-height: 19px;

		strong {
			font-weight: 500;
		}
	}

	ul {
		display: flex;
		list-style: none;
		margin: 0;
		padding: 0;
		gap: 85px;

		li {
			display: flex;
			gap: 10px;
			color: #222;
			font-size: 18px;
			font-weight: 400;
			line-height: 19px;

			strong {
				font-weight: 500;
			}
		}
	}
}

.partner-questionnaire-item {
	&.documentation {
		margin-bottom: 80px;

		.partner-questionnaire-item__sup ul {
			flex-direction: column;
			gap: 30px;
		}
	}

	&.competencies {
		width: 100%;
	}
}

.partner-questionnaire-item__sup-row {
	display: flex;
	gap: 50px;
}

.partner-questionnaire-item.competencies .partner-questionnaire-item__sup {
	flex: 1;
	display: flex;
	align-items: flex-start;
	gap: 30px;

	td {
		padding-right: 30px;
		padding-bottom: 30px;
		color: #222;
		font-size: 18px;
		font-weight: 400;
		line-height: 20px;
		border-right: 1px solid #ebf1f8;

		strong {
			font-weight: 500;
		}

		&:last-child {
			padding-left: 30px;
			padding-right: 0;
			border: none;
		}
	}

	tr:last-child td {
		padding-bottom: 0;
	}
}

.rotate {
	transform: rotate(180deg);
}

.partner-reviews {
	margin-top: 80px;

	h2 {
		margin-bottom: 50px;
		color: #222;
		font-size: 26px;
		font-weight: 500;
		line-height: 30px;
		padding-bottom: 10px;
		position: relative;

		&::before {
			content: '';
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			height: 1px;
			border-radius: 1px;
			background: #ebf1f8;
		}
	}
}

.partner-reviews__row {
	display: flex;
	gap: 30px;
	align-items: flex-start;
}

.partner-reviews__block {
	max-width: 1238px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.partner-reviews__sort {
	display: flex;
	align-items: center;
	gap: 20px;

	p {
		color: #6a6a6b;
		font-size: 18px;
		font-weight: 400;
		line-height: 22px;
	}

	button {
		border: none;
		background: none;
		cursor: pointer;
		outline: none;
		color: #222;
		font-size: 18px;
		font-weight: 400;
		line-height: 22px;
		transition: all 0.3s;

		&.active {
			color: #8a7de7;
		}

		path {
			transition: all 0.3s;
		}

		&:hover {
			color: #4e41ab;

			path {
				stroke: #4e41ab;
			}
		}
	}
}

.partner-review {
	margin-bottom: 20px;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 30px;
}

.partner-review__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.partner-review__name p {
	color: #6a6a6b;
	font-size: 18px;
	font-weight: 400;
	line-height: 22px;
	display: flex;
	align-items: center;
	gap: 10px;

	strong {
		color: #222;
		font-weight: 500;
	}
}

.partner-review__report button {
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	cursor: pointer;
	background: none;
	outline: none;

	path,
	circle {
		transition: all 0.3s;
	}

	&:hover {
		path {
			stroke: #4e41ab;
		}

		circle {
			fill: #4e41ab;
		}
	}
}

.partner-review__rating {
	margin-top: 10px;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		gap: 7px;
	}
}

.partner-review__text {
	color: #222;
	font-size: 16px;
	font-weight: 400;
	line-height: 150%;
	margin-top: 15px;
}

.partner-review__bottom {
	display: flex;
	justify-content: space-between;
	margin-top: 15px;
	gap: 10px;
}

.partner-review__vote {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 15px;
	flex: 1;
}

.vote-finger {
	display: flex;
	align-items: center;
	gap: 5px;
	color: #6a6a6b;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;

	button {
		background: none;
		border: none;
		outline: none;
		display: flex;
		align-items: center;
		justify-content: center;

		path {
			transition: all 0.3s;
		}
	}

	&.active button path {
		stroke: #4e41ab;
		fill: #4e41ab;
	}

	button:hover path {
		fill: #4e41ab;
	}
}

.partner-reviews__block .next-previous-page button {
	max-width: 100%;
	height: 60px;
}

.raging-statistics {
	max-width: 390px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	box-sizing: border-box;
	padding: 30px;
}

.raging-statistics__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 10px;
	padding-bottom: 10px;
	position: relative;

	p span {
		display: none;
	}

	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 1px;
		background: rgba(218, 205, 255, 0.5);
	}

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		gap: 7px;
	}

	p {
		color: #222;
		text-align: right;
		font-size: 18px;
		font-weight: 500;
		line-height: 22px;
	}
}

.raging-statistics__count-reviews {
	color: #6a6a6b;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	margin-top: 15px;
	margin-bottom: 15px;
}

.raging-statistics-star {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 5px;

	.star {
		display: flex;
		align-items: center;
		gap: 3px;
		color: #222;
		font-size: 16px;
		font-weight: 400;
		line-height: 16px;
		justify-content: space-between;
		width: 35px;
	}

	.rating-bar {
		max-width: 245px;
		width: 100%;
		height: 5px;
		background: #ededed;
		border-radius: 5px;
		overflow: hidden;
	}

	.rating-bar__line {
		background: #8a7de7;
		height: 5px;
		border-radius: 5px;
	}

	.voting-count {
		width: 31px;
	}
}

.raging-statistics__note {
	color: #6a6a6b;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	margin-top: 15px;
}

.partner-answer-btn {
	button {
		background: none;
		display: flex;
		align-items: center;
		gap: 7px;
		background: none;
		border: none;
		cursor: pointer;
		color: #8a7de7;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		font-family: 'Montserrat';
		transition: all 0.3s;

		path {
			transition: all 0.3s;
		}

		&:hover {
			color: #4e41ab;

			path {
				stroke: #4e41ab;
			}
		}
	}

	&.open button {
		color: #4e41ab;

		path {
			stroke: #4e41ab;
		}

		svg {
			transform: rotate(180deg);
		}
	}
}

.partner-answer {
	margin-top: 20px;
	color: #222;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	display: none;

	&.open {
		display: block;
	}
}

.partner-answer__report {
	margin-top: 20px;

	button {
		background: none;
		border: none;
		outline: none;
		cursor: pointer;
		color: #8a7de7;
		font-family: 'Montserrat';
		font-size: 14px;
		font-weight: 500;
		line-height: 18px;
		padding-bottom: 3px;
		border-bottom: 1px dashed #8a7de7;
		transition: all 0.3s;

		&:hover {
			color: #4e41ab;
			border-color: #4e41ab;
		}
	}
}

.partner-reply button {
	background: none;
	border: none;
	outline: none;
	cursor: pointer;
	font-family: 'Montserrat';
	color: #8a7de7;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
	transition: all 0.3s;

	&:hover {
		color: #4e41ab;
	}
}

.partner-reply-form {
	margin-bottom: 20px;
	display: none;

	&.open {
		display: block;
	}

	.textarea-block {
		margin-bottom: 10px;

		p {
			color: #6a6a6b;
			font-size: 18px;
			font-weight: 400;
			line-height: 22px;
			margin-bottom: 15px;
		}

		textarea {
			background: none;
			border: 1px solid #777;

			&:focus {
				padding-top: 20px;
			}
		}
	}
}

.partner-reply-form__btns {
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	margin-top: 15px;

	.btn {
		max-width: 200px;
	}

	.cancel {
		outline: none;
		background: none;
		max-width: 200px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 50px;
		box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
		border-radius: 10px;
		border: 1px solid #777;
		color: #333;
		font-family: 'Montserrat';
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		transition: all 0.3s;

		&:hover {
			color: #fff;
			background: #4e41ab;
		}
	}
}

.partner-reply-form .rule {
	color: #6a6a6b;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;

	a {
		color: #8a7de7;
		transition: all 0.3s;
		text-decoration: none !important;

		&:hover {
			color: #4e41ab;
		}
	}
}

@media (max-width: 1440px) {
	.supplier-partner__list {
		gap: 15px;

		li {
			width: calc(16.666% - 13px);
			padding: 20px;
		}
	}
}

@media (max-width: 1200px) {
	.supplier-partner__list li {
		width: calc(20% - 12px);
		padding: 20px;
	}

	.partner-header__title h1 {
		font-size: 30px;
		font-weight: 500;
		line-height: 38px;
	}

	.supplier-partner p {
		font-size: 20px;
		font-weight: 400;
		line-height: 28px;
	}

	.partner-contacts__list {
		flex-direction: column;

		ul li {
			font-size: 16px;
			margin-bottom: 15px;
		}
	}

	.partner-message-client p {
		font-size: 16px;
		line-height: 26px;
	}

	.partner-information__item h2 {
		font-size: 22px;
		font-weight: 500;
		line-height: 26px;
		margin-bottom: 25px;
	}

	.partner-contacts__list ul li a {
		font-size: 16px;
		font-weight: 500;
		line-height: 24px;
	}

	.partner-contacts__call .btn {
		height: 50px;
		max-width: 240px;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
	}

	.partner-works-countries ul {
		gap: 15px;

		li {
			font-size: 16px;
			font-weight: 400;
			line-height: 18px;

			svg {
				width: 31px;
				height: 20px;
			}
		}
	}

	.partner-questionnaire-item__sup ul li {
		font-size: 16px;
		line-height: 24px;
	}

	.partner-questionnaire-item.competencies .partner-questionnaire-item__sup td,
	.partner-questionnaire-item__sup p {
		font-size: 16px;
	}

	.partner-questionnaire-item.competencies .partner-questionnaire-item__sup td {
		padding-right: 20px;
		padding-bottom: 20px;

		&:last-child {
			padding-left: 20px;
		}
	}

	.partner-reviews__row {
		flex-direction: column-reverse;
	}

	.raging-statistics {
		max-width: 100%;
	}

	.raging-statistics__header p {
		display: flex;
		align-items: center;
		gap: 13px;
		font-size: 16px;
		line-height: 20px;

		span {
			display: block;
			color: #6a6a6b;
			font-size: 14px;
			font-weight: 400;
			line-height: 20px;
		}
	}

	.raging-statistics__count-reviews {
		display: none;
	}

	.raging-statistics {
		max-width: 100%;
		display: flex;
		justify-content: space-between;
		margin-top: 0;
		align-items: flex-start;
		gap: 10px;
	}

	.raging-statistics__note {
		display: none;
	}

	.raging-statistics-stars {
		max-width: 333px;
		width: 100%;
	}

	.raging-statistics__block-for-mob {
		max-width: 615px;
		width: 100%;
	}

	.raging-statistics__header {
		padding-bottom: 15px;
	}

	.raging-statistics__block-for-mob .raging-statistics__note {
		display: block;
		color: #6a6a6b;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		margin-top: 15px;
	}

	.raging-statistics-star {
		.star,
		.voting-count {
			font-size: 13px;
			line-height: 17px;
		}
	}

	.partner-questionnaire-item.documentation {
		margin-bottom: 56px;
	}
}

@media (max-width: 1024px) {
	.partner-questionnaire-item__sup ul {
		gap: 20px;
		flex-direction: column;
	}

	.partner-questionnaire-item {
		&.documentation .partner-questionnaire-item__sup ul {
			gap: 20px;
		}

		margin-bottom: 30px;

		> p {
			font-size: 16px;
		}
	}

	.partner-questionnaire-item__sup {
		margin-top: 20px;

		p {
			font-size: 14px;
		}
	}

	.partner-questionnaire-item.competencies .partner-questionnaire-item__sup td {
		font-size: 14px;
	}
}

.raging-statistics-star .star-mob {
	display: none;
}

.vender-page .supplier-partner p {
	margin-bottom: 10px;
}

.vender-countries {
	margin-bottom: 40px;
}

@media (max-width: 768px) {
	.supplier-partner__list li {
		width: calc(25% - 12px);
		padding: 20px;
	}

	.partner-information__row {
		flex-direction: column;
		gap: 30px;
	}

	.partner-information__item {
		width: 100%;
	}

	.partner-contacts__call {
		margin-top: 0;
	}

	.partner-questionnaire__row {
		flex-direction: column;
		gap: 30px;
	}

	.partner-questionnaire__list {
		max-width: 100%;
		width: 100%;
	}

	.partner-questionnaire-item.documentation {
		margin-bottom: 30px;
	}

	.raging-statistics__header ul,
	.raging-statistics__block-for-mob .raging-statistics__note {
		display: none;
	}

	.raging-statistics__note {
		display: block;
		margin-top: 10px;
		padding-top: 10px;
		position: relative;
		max-width: 100%;
		width: 100%;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 1px;
			border-radius: 1px;
			background: rgba(218, 205, 255, 0.5);
		}
	}

	.raging-statistics {
		flex-wrap: wrap;
		gap: 0 30px;
		align-items: center;
		padding: 15px;
	}

	.raging-statistics__header {
		padding-bottom: 0;
	}

	.raging-statistics__block-for-mob {
		max-width: 200px;
	}

	.raging-statistics__header::before {
		display: none;
	}

	.raging-statistics-stars {
		max-width: 100%;
		width: 100%;
		flex: 1;
	}

	.raging-statistics-star {
		.star {
			display: none;
		}

		.star-mob {
			display: flex;
			gap: 5px;
			min-width: 105px;
			justify-content: flex-end;

			svg {
				width: 17px;
				height: 17px;
			}
		}

		.voting-count {
			display: none;
		}

		.rating-bar {
			max-width: 100%;
		}
	}

	.raging-statistics__header p {
		flex-direction: column;
		align-items: flex-start;
		gap: 5px;
		font-size: 50px;
		line-height: 55px;

		span {
			font-size: 25px;
			line-height: 25px;
		}
	}

	.partner-review {
		padding: 15px;
	}

	.partner-questionnaire-item__sup-row {
		display: flex;
		gap: 30px;
		flex-direction: column;
	}

	.partner-questionnaire-item.competencies .partner-questionnaire-item__sup p {
		font-size: 16px;
	}
}

@media (max-width: 480px) {
	.raging-statistics-star .star-mob {
		min-width: 35px;
	}

	.raging-statistics-stars {
		max-width: 190px;
		width: 100%;
		flex: 1 1;
	}

	.raging-statistics {
		gap: 0 20px;
	}

	.partner-page {
		padding: 40px 0;
	}

	.partner-header {
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.btn-back {
		display: none !important;
	}

	.partner-header__title {
		display: flex;
		align-items: center;
		gap: 20px;
		max-width: 100%;
		width: 100%;
		justify-content: space-between;

		h1 {
			font-size: 20px;
			font-weight: 500;
			line-height: 26px;
		}

		.rating {
			gap: 5px;
			font-size: 16px;

			svg {
				width: 20px;
				height: 20px;
			}
		}
	}

	.supplier-partner {
		margin-top: 30px;

		p {
			font-size: 15px;
			line-height: 21px;
			margin-bottom: 15px;
		}
	}

	.supplier-partner__list {
		gap: 10px;

		li {
			width: calc(33.333% - 7px);
			padding: 10px;
			height: 50px;
			border-radius: 8px;
		}
	}

	.partner-information {
		padding: 30px 0;
		margin: 30px 0;
	}

	.partner-information__item h2 {
		font-size: 17px;
		line-height: 21px;
		padding-bottom: 5px;
		margin-bottom: 20px;
	}

	.partner-message-client p {
		font-size: 14px;
		font-weight: 400;
		line-height: 22px;
	}

	.partner-contacts__list {
		ul li {
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 10px;

			svg {
				width: 18px;
				height: 18px;
			}

			a {
				font-size: 14px;
				line-height: 22px;
			}
		}

		gap: 20px;
	}

	.partner-works-countries ul {
		li {
			font-size: 14px;
			line-height: 16px;

			svg {
				width: 27px;
				height: 18px;
			}
		}

		gap: 20px;
	}

	.partner-contacts__call .btn {
		max-width: 100%;
		height: 40px;
		border-radius: 6px;
		font-size: 13px;
		font-weight: 500;
		line-height: 12px;
	}

	.partner-questionnaire-item__sup ul li {
		font-size: 14px;
		line-height: 20px;
		flex-direction: column;
	}

	.partner-questionnaire-item {
		&.documentation .partner-questionnaire-item__sup ul {
			gap: 15px;
		}

		&.competencies .partner-questionnaire-item__sup {
			flex-direction: column;
		}
	}

	.raging-statistics {
		border-radius: 6px;
	}

	.partner-reviews {
		margin-top: 30px;

		h2 {
			margin-bottom: 20px;
		}
	}

	.raging-statistics__header p {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;

		span {
			font-size: 10px;
			font-weight: 400;
			line-height: 14px;
		}
	}

	.raging-statistics__block-for-mob {
		max-width: 80px;
	}

	.raging-statistics-star {
		.star-mob {
			gap: 0;

			svg {
				width: 7px;
				height: 7px;
			}
		}

		.rating-bar {
			height: 2px;
		}

		margin-bottom: 7px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.raging-statistics__note {
		color: #6a6a6b;
		font-size: 8px;
		line-height: 12px;
	}

	.partner-reviews__sort {
		gap: 20px;

		p {
			font-size: 12px;
			line-height: 16px;
		}

		button {
			font-size: 12px;
			line-height: 16px;

			svg {
				width: 10px;
				height: 10px;
			}
		}
	}

	.partner-review__name p {
		font-size: 11px;
		line-height: 14px;
	}

	.partner-review__report button svg {
		width: 17px;
		height: 17px;
	}

	.partner-review__rating {
		margin-top: 5px;
	}

	.partner-review__text {
		margin-top: 10px;
		font-size: 10px;
		line-height: 16px;
	}

	.partner-review__rating {
		ul {
			gap: 4px;
		}

		svg {
			width: 15px;
			height: 15px;
		}
	}

	.partner-reply button {
		font-size: 11px;
		line-height: 14px;
	}

	.vote-finger {
		button svg {
			width: 19px;
			height: 19px;
		}

		font-size: 11px;
		line-height: 17px;
	}

	.partner-review__bottom {
		margin-top: 10px;
	}

	.partner-reply-form {
		.textarea-block {
			p {
				font-size: 11px;
				line-height: 14px;
				margin-bottom: 10px;
			}

			textarea::placeholder {
				font-size: 10px;
			}
		}

		.rule {
			font-size: 9px;
			line-height: 13px;
		}
	}

	.partner-reply-form__btns {
		justify-content: space-between;
		gap: 10px;
		margin-top: 20px;

		.cancel {
			height: 40px;
			border-radius: 6px;
			font-size: 13px;
			line-height: 12px;
		}
	}

	.partner-reviews__block .next-previous-page {
		button {
			height: 40px;
		}

		margin-top: 20px;
	}

	.partner-answer-btn button {
		font-size: 11px;
		line-height: 14px;
	}

	.partner-answer {
		font-size: 10px;
		line-height: 16px;
	}

	.partner-answer__report button {
		font-size: 11px;
		line-height: 14px;
	}

	.partner-header__title img {
		max-width: 50px;
	}
}
