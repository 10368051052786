.wrapper {
  width: 310px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  border: 1px solid #E8E5E5;
  box-sizing: border-box;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.08), 0px 5px 27px 0px rgba(0, 0, 0, 0.09);


  @media (max-width:567px) {
    margin-right: 5px !important;
  }



  :global {
    .typeAlert {
      display: flex;
      flex: 0 0 40px;
    }
  }


  button {
    background: none;
    border: none;
    width: 50px;
    height: 50px;
    border-left: 1px solid #E8E5E5;
    font-size: 14px;
    cursor: pointer;
    flex: 0 0 50px;
  }

  &.info {
    color: #1c1c1f;
    background: #95d9ee;
    background: #cde5f5;
    // background: white;


    button {
      color: #1c1c1f;

    }
  }

  &.success {
    color: #54C86E;
    background: rgba(84, 200, 110, 0.18);
    background: #b0ffb0;
    color: #1c1c1f;

    button {
      color: #54C86E;
      color: #1c1c1f;
      font-weight: 500;

    }
  }

  &.error {
    color: #DC0000;

    button {
      color: #DC0000;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 15px;
  gap: 5px;

  @media (max-width:567px) {
    padding-top: 0;
    padding-bottom: 0;
  }

}