.auth-block {
	max-width: 563px;
	width: 100%;
	border-radius: 15px;
	border: 1px solid #f6f6f6;
	background: #fff;
	box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.03);
	margin: 0 auto;
	box-sizing: border-box;
	padding: 70px 80px;
}

.input-block {
	.input-note {
		top: 20px;
		right: 20px;
		transform: translate(0);
	}
}

// .input-placeholder {
// 	position: absolute;
// 	left: 20px;
// 	top: 20px;
// 	font-family: 'Montserrat';
// 	color: #777;
// 	font-size: 15px;
// 	font-weight: 400;
// 	line-height: 18px;
// 	transition: all 0.3s;
// 	padding-right: 25px;

// 	span {
// 		color: #f00;
// 	}
// }
