.wrapper {
  background: #8a7de7;
  border-radius: 100%;
  width: 50px;
  height: 50px;
  display: flex;
  right: 20px;
  bottom: 50px;
  position: fixed;
  cursor: pointer;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 1px 0px 12px 3px #44444480;
  z-index: 99;



  svg {
    fill: #fff
  }
}