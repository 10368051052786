.profile-container {
    max-width: 760px;
    width: 100%;
    margin-left: 30px;
  }
  
  .warning-text {
    margin: 0;
    padding: 0;
    width: 100%;
    margin-bottom: 30px;
  }
  
  /* .warning-text p {
      color: #6A6A6B;
      font-family: 'Montserrat';
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 10px;
  } */
  
  .info-help {
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  
    p {
      color: #333;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      transition: all 0.3s;
    }
  
    &:hover p {
      color: #4E41AB;
    }
  
    svg path {
      stroke: #8A7DE7;
      transition: all 0.3s;
    }
  
    &:hover path {
      stroke: #4E41AB;
    }
  }
  
  .two-on-line {
    display: flex;
    justify-content: space-between;
    gap: 5%;
  
    div {
      &:first-child:not(.css-b62m3t-container, .css-b62m3t-container div), &:nth-child(2):not(.css-b62m3t-container, .css-b62m3t-container div) {
        flex-grow: 1;
        width: 40%;
      }
    }
  }
  
  @media (max-width: 1060px) {
    .profile-container {
      margin-left: 0;
    }
  }
  
  @media (max-width: 480px) {
    .info-help {
      p {
        font-size: 16px;
        line-height: 22px;
      }
  
      margin-top: 0;
  
      svg {
        width: 27px;
        height: 27px;
      }
    }
  }
  