@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900);
@import 'mixins';
* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-size: 100%;
	font-family: 'Montserrat';
}

body {
	font-family: 'Montserrat';
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

@font-face {
	font-family: 'Inter800';
	src: url('../../../public//fonts//Inter/Inter-ExtraBold.ttf') format('truetype');
	font-family: 'Montserrat';
	src: url('../../../public//fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
	font-family: 'Montserrat';
	font-style: italic;
	src: url('../../../public//fonts/Montserrat/Montserrat-Italic-VariableFont_wght.ttf') format('truetype');
}