.landing-banner {
    margin-top: 100px;
    width: 100%;
}

.landing-banner__block {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    border-radius: 20px;
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    height: 551px;
    position: relative;
    width: 100%;
    align-items: center;
}

.landing-banner__info {
    box-sizing: border-box;
    padding-left: 50px;
    max-width: 600px;
    width: 100%;
}

.landing-banner__info h1 {
    color: #FFF;
    font-size: 38px;
    font-weight: 600;
    line-height: 46px;
    text-transform: uppercase;
}

.landing-banner__info h1 span {
    color: #20BF55;
    font-size: 44px;
    font-weight: 700;
    line-height: 50px;
    display: block;
}

.about-banner .landing-banner__info {
    max-width: 630px;
}

.landing-banner__info p {
    margin-top: 25px;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
}

.landing-banner__info button {
    border-radius: 10px;
    background: #FFF;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 265px;
    width: 100%;
    height: 52px;
    color: #8A7DE7;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    transition: all 0.3s;
    cursor: pointer;
    outline: none;
    border: none;
    text-decoration: none;
}

.landing-banner__info button:hover {
    color: #fff;
    background: #20BF55;
}

.landing-banner__img {
    max-width: 1000px;
    position: relative;
    width: 100%;
}

.landing-banner__img img {
    position: relative;
    max-height: 695px;
    width: auto;
    max-width: 100%;
}

.about-banner .landing-banner__img {
    max-width: 900px;
}

.landing-section2 {
    padding-top: 110px;
    padding-bottom: 100px;
    max-width: 100%;
    width: 100%;
}

.landing-title {
    margin-bottom: 95px;
}

.landing-title h2 {
    color: #8A7DE7;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    text-transform: uppercase;
}

.landing-section2__list {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
}

.landing-section2-item {
    position: relative;
    width: calc(33.333% - 40px);
    box-sizing: border-box;
    padding: 95px 30px 30px 30px;
    border-radius: 18px;
}

.landing-section2-item::before {
    content: '';
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    transition: all 0.3s;
    opacity: 1;
    border: 1px solid #F2F6FA;
}

.landing-section2-item::after {
    content: '';
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 18px;
    transition: all 0.3s;
    opacity: 0;
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
}

.landing-section2-item:hover::before {
    opacity: 0;
}

.landing-section2-item:hover::after {
    opacity: 1;
}


.landing-section2-item__icon {
    position: absolute;
    width: 116px;
    height: 116px;
    background: #F9F9FB;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
}

.landing-section2-item__icon span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 84px;
    height: 84px;
    border-radius: 100%;
    position: relative;
    filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
}

.landing-section2-item__icon span svg {
    position: relative;
    transition: all 0.3s;
    z-index: 3;
}


.landing-section2-item__icon span path,
.landing-section2-item__icon span circle {
    transition: all 0.3s;
}

.landing-section2-item:hover .landing-section2-item__icon span path,
.landing-section2-item:hover .landing-section2-item__icon span circle {
    stroke: #8A7DE7;
}

.landing-section2-item__icon span::before {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s;
}

.landing-section2-item__icon span::after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 100%;
    filter: drop-shadow(0px 2px 10px rgba(12, 11, 11, 0.05));
    background: #fff;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.3s;
}

.landing-section2-item:hover .landing-section2-item__icon span::before {
    opacity: 0;
}

.landing-section2-item:hover .landing-section2-item__icon span::after {
    opacity: 1;
}

.landing-section2-item__title {
    position: relative;
    color: #333;
    font-size: 23px;
    font-weight: 600;
    line-height: 31px;
    transition: all 0.3s;
    z-index: 3;
}

.landing-section2-item:hover .landing-section2-item__title {
    color: #fff;
}

.landing-section2-item__text {
    position: relative;
    margin-top: 20px;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    transition: all 0.3s;
    z-index: 3;
}

.landing-section2-item:hover .landing-section2-item__text {
    color: #fff;
}

.landing-section3 {
    max-width: 100%;
    width: 100%;
}

.landing-section3__list {
    display: flex;
    gap: 120px 60px;
    flex-wrap: wrap;
}

.landing-section3-item {
    width: calc(50% - 30px);
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
    max-height: 175px;
    height: 100%;
    box-sizing: border-box;
    padding-left: 20px;
    padding-right: 30px;
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    border-radius: 18px;
    border: 1px solid #F6F6F6;
}

.landing-section3-item::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #fff;
    transition: all 0.3s;
    opacity: 1;
    border-radius: 18px;
}

.landing-section3-item::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    transition: all 0.3s;
    opacity: 0;
    border-radius: 18px;
}

.landing-section3-item:hover::before {
    opacity: 0;
}

.landing-section3-item:hover::after {
    opacity: 1;
}

.landing-section3-item__img {
    width: 282px;
    height: 282px;
    position: relative;
    z-index: 3;
}

.landing-section3-item__img img {
    height: 100%;
    width: auto;
}

.landing-section3-item__text {
    position: relative;
    z-index: 3;
    color: #333;
    font-size: 23px;
    font-weight: 500;
    line-height: 31px;
    transition: all 0.3s;
}

.landing-section3-item:hover .landing-section3-item__text {
    color: #fff;
}


.landing-section4 {
    width: 100%;
    max-width: 100%;
    padding-top: 160px;
    padding-bottom: 100px;
}

.landing-section4__row {
    display: flex;
    gap: 66px;
    align-items: center;
}

.landing-section4__info {
    max-width: 748px;
    width: 100%;
}

.landing-section4__info h2 {
    color: #8A7DE7;
    font-size: 36px;
    font-weight: 700;
    line-height: 44px;
    text-transform: uppercase;
    margin-bottom: 50px;
}

.landing-section4__info p {
    margin-bottom: 20px;
    color: #333;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}

.landing-section4__info .btn {
    max-width: 350px;
    height: 68px;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    margin-top: 50px;
}

.landing-section4__img  {
    max-width: 846px;
    width: 100%;
}

.landing-section4__img img {
    max-width: 100%;
}

.banner-support {
    width: 100%;
    max-width: 100%;
    padding-bottom: 80px;
}

.banner-support__block {
    box-shadow: 0px 2px 10px 0px rgba(12, 11, 11, 0.05);
    background: radial-gradient(1414.12% 141.42% at 100% 100%, #8A7DE7 0%, #4E41AB 100%);
    border-radius: 17px;
    box-sizing: border-box;
    padding: 80px 30px;
    text-align: center;
    position: relative;
}

.banner-support h2 {
    margin-bottom: 10px;
    color: #FFF;
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
    text-transform: uppercase;
    position: relative;
}

.banner-support h3 {
    margin-top: 20px;
    color: #FFF;
    font-size: 22px;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
    position: relative;
}

.banner-support p {
    margin-top: 7px;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
}

.banner-support__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    position: relative;
}

.banner-support__btn a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
    border-radius: 10px;
    background: #FFF;
    max-width: 349px;
    width: 100%;
    height: 66px;
    color: #8A7DE7;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    transition: all 0.3s;
}

.banner-support__btn a:hover {
    background: #4E41AB;
    color: #fff;
}

.blob {
    position: absolute;
}

.blob1 {
    left: 0;
    bottom: -50px;
}

.blob2 {
    right: 0;
    top: -35px;
}

.blob3 {
    right: 0;
    bottom: -35px;
}

.landing-section3__link {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
}

.landing-section3__link a {
    display: flex;
    align-items: center;
    gap: 7px;
    text-decoration: none;
    color: #8A7DE7;
    transition: all 0.3s;
}

.landing-section3__link a path {
    transition: all 0.3s;
}

.landing-section3__link a:hover {
    color: #4E41AB;
}

.landing-section3__link a:hover path {
    stroke: #4E41AB;
}

@media(max-width: 1440px) {

    .landing-banner {
        margin-top: 70px;
    }

    .landing-banner__img {
        max-width: 770px;
    }

    .landing-banner__info {
        padding-left: 40px;
    }

    .landing-banner__info h1 {
        font-size: 34px;
        line-height: 43px
    }

    .landing-banner__info h1 span {
        font-size: 39px;
        line-height: 45px;
    }

    .landing-banner__block {
        height: 470px;
    }

    .landing-section2 {
        padding-top: 80px;
        padding-bottom: 100px;
    }

    .landing-title h2 {
        font-size: 34px;
        line-height: 42px;
    }

    .landing-section2-item__title {
        font-size: 20px;
        line-height: 26px
    }

    .landing-section2-item__text {
        font-size: 16px;
        line-height: 26px;
    }

    .landing-section2__list {
        gap: 30px;
    }

    .landing-section2-item {
        width: calc(33.333% - 20px);
        padding: 90px 20px 30px 20px;
    }

    .landing-section3-item__text {
        font-size: 20px;
        line-height: 28px
    }

    .landing-section3-item__img {
        width: 245px;
        height: 245px;
    }

    .landing-section3-item {
        height: 153px;
        width: calc(50% - 15px);
    }

    .landing-section3__list {
        gap: 90px 30px;
    }

    .landing-section4__info h2 {
        font-size: 34px;
        line-height: 42px;
    }
}

@media(max-width: 1060px) {

    .blob {
        width: 40%;
    }

    .blob1 {
        left: -75px;
        bottom: 0;
    }

    .blob2 {
        right: 30px;
        top: -30px;
    }

    .blob3 {
        right: 30px;
        bottom: -40px;
    }

    .blob img {
        max-width: 100%;
    }

    .banner-support h2 {
        font-size: 24px;
        line-height: 30px;
    }

    .banner-support h3 {
        font-size: 16px;
        line-height: 24px;
    }

    .banner-support p {
        font-size: 14px;
        line-height: 20px;
    }

    .banner-support p {
        font-size: 14px;
        line-height: 20px;
        max-width: 455px;
        width: 100%;
        margin: 0 auto;
        margin-top: 5px;
    }

    .banner-support__btn a {
        max-width: 320px;
        height: 50px;
    }

    .banner-support__block {
        padding: 55px 30px;
    }

    .banner-support {
        padding: 0px 20px 80px 20px;
    }

    .landing-banner {
        margin-top: 50px;
    }

    .landing-banner__img {
        max-width: 560px;
    }

    .landing-banner__info {
        padding-left: 30px;
    }

    .landing-banner__info h1 {
        font-size: 24px;
        line-height: 33px;
    }

    .landing-banner__info h1 span {
        font-size: 29px;
        line-height: 35px;
    }

    .landing-banner__block {
        height: 400px;
    }

    .landing-banner__info p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 15px;
    }

    .landing-banner__info p br {
        display: none;
    }

    .landing-title h2 {
        font-size: 26px;
        line-height: 34px;
    }

    .landing-section2 {
        padding: 70px 0;
    }

    .landing-title {
        margin-bottom: 65px;
    }

    .landing-section2-item__title {
        font-size: 14px;
        line-height: 20px;
    }

    .landing-section2-item__text {
        margin-top: 15px;
        font-size: 12px;
        line-height: 20px
    }

    .landing-section2__list {
        gap: 20px;
    }

    .landing-section2-item {
        width: calc(33.333% - 14px);
        padding: 70px 20px 20px 20px;
    }

    .landing-section2-item__icon {
        width: 90px;
        height: 90px;
    }

    .landing-section2-item__icon span {
        width: 64px;
        height: 64px;
    }

    .landing-section2-item__icon svg {
        width: 34px;
        height: 34px;
    }

    .landing-section3__list {
        gap: 60px 20px;
    }

    .landing-section3-item {
        height: 113px;
        width: calc(50% - 10px);
        padding: 0 15px;
        gap: 20px;
    }

    .landing-section3-item__img {
        width: 175px;
        height: 175px;
    }

    .landing-section3-item__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px
    }

    .landing-section4 {
        padding-top: 115px;
        padding-bottom: 0;
    }
    
    .landing-section4__row {
        gap: 20px;
    }

    .landing-section4__info {
        max-width: 390px;
        width: 100%;
    }

    .landing-section4__info h2 {
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 35px;
    }

    .landing-section4__info p {
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 22px;
    }

    .landing-section4__info .btn {
        margin-top: 35px;
        height: 50px;
        font-size: 14px;
        max-width: 265px;
    }

    .landing-support {
        padding: 0;
        padding-bottom: 50px;
    }
}

@media(max-width: 768px)  {
    .landing-banner {
        padding: 0;
        margin: 0;
    }

    .landing-banner .container {
        padding: 0;
    }

    .landing-banner__block {
        border-radius: 0;
        height: auto;
        padding: 30px 20px;
    }

    .landing-banner__info {
        padding-left: 0;
    }

    .landing-section3-item {
        flex-direction: column;
        height: auto;
        max-height: 100%;
        padding: 0 13px 20px 13px;
        gap: 10px;
    }

    .landing-section3-item__img {
        height: 114px;
        width: 114px;
        position: relative;
        margin-top: -50px;
    }

    .landing-section4 {
        padding-top: 70px;
    }

    .landing-section4__row {
        gap: 25px;
        flex-direction: column;
    }

    .landing-section4__info {
        max-width: 100%;
        width: 100%;
    }

    .landing-section4__img {
        max-width: 100%;
    }

    .landing-section4__info .btn {
        max-width: 100%;
    }
}

@media(max-width: 480px) {

    .banner-support {
        padding: 0px 0px 60px 0px;
    }

    .blob1 {
        left: 0;
        bottom: 0;
    }

    .blob2 {
        right: 0;
        top: 0;
    }

    .blob3 {
        right: 0;
        bottom: 0px;
    }

    .banner-support__block {
        padding: 35px 20px;
        margin-top: 50px;
        border-radius: 0;
    }

    .banner-support h2 {
        font-size: 18px;
        line-height: 26px;
    }

    .banner-support h3 {
        font-size: 13px;
        line-height: 24px;
        margin-top: 15px;
    }

    .banner-support p {
        max-width: 260px;
        font-size: 10px;
        line-height: 16px;
    }

    .banner-support__btn a {
        max-width: 100%;
        height: 50px;
    }

    .landing-banner__info p {
        font-size: 10px;
        font-weight: 500;
        line-height: 18px;
    }

    .landing-banner__block {
        flex-direction: column;
        padding: 30px 20px 15px 20px;
    }

    .landing-banner__info button {
        margin-top: 25px;
        max-width: 180px;
        height: 40px;
        font-size: 10px;
        line-height: 14px;
    }

    .landing-banner__info h1 {
        font-size: 20px;
        line-height: 27px;
        margin-bottom: 10px;
    }

    .landing-banner__info h1 span {
        font-size: 22px;
        line-height: 28px
    }

    .landing-section2 {
        padding: 50px 0;
    }

    .landing-title {
        margin-bottom: 60px;
    }

    .landing-title h2 {
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
    }

    .landing-section2-item {
        width: 100%;
        padding: 55px 20px 35px 20px;
        border-radius: 10px;
    }

    .landing-section2-item__text {
        font-size: 10px;
        line-height: 16px
    }

    .landing-section3__list {
        gap: 60px 20px;
    }

    .landing-section3-item::after,
    .landing-section3-item::before {
        border-radius: 10px;
    }

    .landing-section3-item__text {
        font-size: 10px;
        line-height: 16px
    }
    
    .landing-section4 {
        padding-top: 50px;
    }

    .landing-section4__info h2 {
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
    }

    .landing-section4__info p {
        font-size: 10px;
        line-height: 16px;
        margin-bottom: 10px;
    }

    .landing-section4__info .btn {
        height: 40px;
        font-size: 10px;
        line-height: 14px;
        margin-top: 15px;
    }

    .landing-support .container {
        padding: 0;
    }

    .landing-support h2 br {
        display: none;
    }

    .landing-support .banner-support__btn a {
        max-width: 180px;
        height: 40px;
        border-radius: 6px;
        font-size: 10px;
        line-height: 14px;
    }

    .banner-support__btn {
        margin-top: 20px;
    }
}