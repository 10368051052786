p.supplier-label {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 10px;
  
    span {
      color: #FF0000;
    }
  }
  
  .distributor-request {
    background: white !important;
    color: #8A7DE7 !important;
    border: 1px solid #8A7DE7 !important;
  }
  
  @media (max-width: 480px) {
    p.supplier-label {
      font-size: 13px;
      line-height: 19px;
    }
  }
  