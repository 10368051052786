.closed-dropdown {
    position: relative;
    border-radius: 10px;
    background: #EBF1F8;
    max-width: 250px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
  
    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
    }
  }
  
  .closed-dropdown-header {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    align-items: center;
    width: 100%;
  
    svg.open {
      transform: rotate(180deg);
    }
  }
  
  .closed-dropdown-body {
    position: absolute;
    width: 100%;
    background-color: #EBF1F8;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    z-index: 1;
    top: 100%;
    left: 0;
    border-top: none;
    display: none;
    padding: 0 1px;
    padding-bottom: 10px;
  
    &.open {
      display: block;
    }
  }
  
  .closed-dropdown-item {
    padding: 5px 15px;
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  
    &:hover {
      cursor: pointer;
      background: #fff;
    }
  }
  
  .closed-dropdown-item-dot {
    opacity: 0;
    color: #91A5BE;
    transition: all .2s ease-in-out;
  
    &.selected {
      opacity: 1;
    }
  }
  
  .icon {
    font-size: 13px;
    color: #91A5BE;
    transform: rotate(0deg);
    transition: all .2s ease-in-out;
  
    &.open {
      transform: rotate(90deg);
    }
  }
  
  @media (max-width: 650px) {
    .closed-dropdown {
      max-width: 100%;
      border-radius: 6px;
    }
  }
  