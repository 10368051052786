.select-language {
  cursor: pointer;
}

.selected-result {
  border-radius: 5px;
  border: 1px solid #8a7de7;
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;

  img {
    width: 30px;
    height: 22px;
  }

  &:hover {
    background: #4e41ab;
    border-color: #4e41ab;
  }
}

.select-language.open .selected-result {
  background: #4e41ab;
  border-color: #4e41ab;
}

.languages-options {
  position: absolute;
  background: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 20px;
  top: 100%;
  transform: translateY(10px);
  flex-direction: column;
  gap: 15px;
  z-index: 66;
  max-height: 185px;
  overflow: hidden;
  overflow-y: auto;
  display: none;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-track {
    background: #ebeaed;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #8a7de7;
  }

  scrollbar-width: thin;
  scrollbar-color: #8a7de7 #ebeaed;

  scrollbar-Track-color: #ebeaed;
  scrollbar-Thumb-color: #8a7de7;
}

.language-option {
  display: flex;
  gap: 10px;
  color: #333;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  transition: all 0.3s;

  img {
    width: 33px;
    height: 23px;
  }

  &:hover {
    color: #4e41ab;
  }
}

.select-language.open .languages-options {
  display: flex;
}

.language-select__placeholder {
  display: none;
}

@media (max-width: 480px) {
  .selected-result {
    width: 35px;
    height: 35px;

    img {
      width: 21px;
      height: 15px;
    }
  }

  .language-option {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    img {
      width: 26px;
      height: 16px;
    }
  }

  .languages-options {
    padding: 15px;
    max-height: 140px;
  }
}