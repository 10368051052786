.footer {
  width: 100%;
  max-width: 100%;
  background: #fff;
  margin: 0;
  padding: 0;
  position: relative;
  top: -4px;

  &-mock {
    width: 200px;

    @media (max-width:1336px) {
      display: none;
    }
  }
}

.footer__top {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  align-items: center;
  gap: 10px;

  @media (max-width:1336px) {
    padding: 22px 0;

  }

  @media (max-width:567px) {
    gap: 3px;
  }
}

.footer__menu {
  max-width: 1215px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 5px;
  min-height: 64px;
  align-items: center;

  @media (max-width:1336px) {
    height: auto;

  }


  a {
    color: #222;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    transition: all 0.3s;
    text-decoration: none;
    font-size: 12px;

    &:hover {
      color: #4E41AB;
    }
  }

  @media (max-width:1060px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    a {
      font-size: 13px;
      white-space: nowrap;
    }
  }

  @media (max-width:768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width:400px) {
    grid-template-columns: repeat(1, 1fr);

  }
}

.footer__logo {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @media (max-width:1336px) {
    position: relative;
    transform: translateY(0%);

  }




  button {
    @media (max-width:768px) {
      font-size: 8px;
    }
  }

  @media (max-width:1336px) {
    bottom: 0;
  }

  p {
    color: #333;
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;
    text-align: right;
    margin-top: 10px;
  }

  .btn {
    margin-top: 10px;
    height: 40px;
    max-width: 220px;
    padding: 0 10px;
  }
}

.footer-bottom {
  position: relative;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.footer-bottom-p {
  @media (max-width:1336px) {
    br {
      display: none;
    }
  }

  @media (max-width:768px) {
    order: 1;

    br {
      display: none;
    }
  }
}

.bgsvg {
  position: absolute;
  left: -100%;
  top: 0;
  width: 100%;
  height: 100%;
  width: 100vh;
  width: 204%;



  svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 100%;
  }
}



.footer-bottom__row {
  position: relative;
  display: flex;
  gap: 10px;
  align-items: center;
  box-sizing: border-box;
  flex: 0 1 calc(100% - 200px);
  justify-content: space-between;
  min-height: 58px;

  @media (max-width:1440px) {
    width: 200%;
    flex: 0 1 calc(100% - 250px);
  }

  @media (max-width:1336px) {
    flex: 1;
    justify-content: space-between;
    min-height: auto;
  }

  p {
    color: #222;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
    position: relative;
    z-index: 1;

    span {
      color: #4E41AB;
    }

    a {
      text-decoration: none;
      color: #222;
      transition: all 0.3s;

      &:hover {
        color: #4E41AB;
      }
    }
  }
}

@media (min-width: 2560px) {}

@media (max-width: 1660px) {
  .footer-bottom {
    justify-content: flex-start;
    box-sizing: border-box;
  }

  .footer-bottom__row {
    gap: 30px;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 1536px) {}

@media (max-width: 1440px) {
  .footer__menu {
    max-width: 1015px;
  }

  .footer__logo {
    .logo {
      max-width: 150px;

      svg {
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    p {
      font-size: 9px;
      line-height: 9px;
    }
  }


}

@media (max-width: 1336px) {
  .footer__logo {
    bottom: 0;
  }

  .footer-bottom {
    margin-top: 0;
    max-width: 100%;
    background: #ebf1f8;
    padding: 15px 20px;

    .bgsvg {
      display: none;
    }
  }

  .footer__menu {
    gap: 30px;
  }
}

@media (max-width: 1200px) {
  .footer-bottom {
    padding: 15px 20px;

    @media (max-width:567px) {
      padding: 15px 0;
    }
  }

  .footer-bottom__row {
    height: auto;
  }
}

@media (max-width: 1060px) {
  .footer__menu {
    gap: 10px;
    flex-wrap: wrap;
    max-width: 460px;
  }

  .footer-bottom__row {
    gap: 10px 30px;
    right: 0;
    flex-wrap: wrap;
    justify-content: flex-start;
    text-align: center;

    p {
      &:first-child {
        width: 100%;
      }

      font-size: 10px;
      text-align: left;
      font-weight: 300;
      line-height: 13px;
    }
  }


}