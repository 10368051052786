.verification-status {
  display: flex;
  gap: 20px;
  align-items: center;
  height: auto;
  box-sizing: border-box;
  padding: 20px 30px;
  background: rgba(138, 125, 231, 0.1);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 44;
  position: relative;
  top: -30px;
  left: -30px;
  width: calc(100% + 60px);

  @media (max-width:1060px) {
    top: -50px;
    left: -50px;
    width: calc(100% + 80px);
  }

  @media (max-width:768px) {
    top: -30px;
    left: -20px;
    width: calc(100% + 30px);
  }

  @media (max-width:480px) {
    top: -15px;
    left: -20px;
  }
}

.verification-status__status {
  border-radius: 5px;
  background: #8A7DE7;
  height: 54px;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 7px;
  color: #FFF;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  white-space: nowrap
}

.verification-status__info {
  color: #333;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;

  a {
    color: #8a7de7;
    text-decoration: none;
    transition: all 0.3s;

    &:hover {
      color: #4e41ab;
    }
  }
}

@media (max-width: 1060px) {
  .verification-status {
    gap: 10px;
    height: auto;
  }

  .verification-status__info {
    font-size: 14px;
    line-height: 22px;
    flex: 1;

    br {
      display: none;
    }
  }

  .verification-status__status {
    padding: 0 10px;
    font-size: 15px;
    line-height: 23px;
    height: 43px;
    gap: 5px;

    svg {
      width: 23px;
      height: 23px;
    }
  }
}

@media (max-width: 480px) {
  .verification-status {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    padding: 15px 20px;
  }

  .verification-status__status {
    font-size: 11px;
    line-height: 19px;

    svg {
      width: 19px;
      height: 19px;
    }
  }

  .verification-status__info {
    font-size: 11px;
    line-height: 15px;
  }
}